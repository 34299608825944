import api from '@/api.js'

const state = {
  organizations: [],
  virtualBrands: [],
  selectedOrganization: null,
  selectedLocation: null
}

const getters = {}

const actions = {
  async refreshOrganizations({ commit }) {
    let response = await api.get('/support/organizations')
    commit('refreshOrganizations', response.data)
  },
  selectOrganization({ commit }, organization) {
    commit('selectOrganization', organization)
  },
  selectLocation({ commit }, location) {
    commit('selectLocation', location)
  },
  removeSelectedOrganization({ commit }) {
    commit('removeSelectedOrganization')
  }
}

const mutations = {
  refreshOrganizations(state, { organizations, virtualBrands }) {
    state.organizations = organizations
    state.virtualBrands = virtualBrands
  },
  selectOrganization(state, organization) {
    state.selectedOrganization = organization
  },
  selectLocation(state, location) {
    state.selectedLocation = location
  },
  removeSelectedOrganization(state) {
    state.selectedOrganization = null
    state.selectedLocation = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
