import api from '@/api.js'

const state = {
  integrations: {}
}

const getters = {}

const actions = {
  async refreshIntegrations({ commit }) {
    let response = await api.get('/support/integrations')
    commit('refreshIntegrations', response.data)
  }
}

const mutations = {
  refreshIntegrations(state, integrations) {
    state.integrations = integrations
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
