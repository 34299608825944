import store from '@/store/index'

let devCode = ['p', 'e', 'a', 'k', 'y']

let devCodePosition = 0

document.addEventListener('keydown', function (e) {
  let key = e.key
  let requiredKey = devCode[devCodePosition]

  if (key == requiredKey) {
    devCodePosition++
    if (devCodePosition == devCode.length) {
      activateCheats()
      devCodePosition = 0
    }
  } else {
    devCodePosition = 0
  }
})

function activateCheats() {
  store.dispatch('auth/setDevMode', true)
}
