<template>
  <div class="px-5 pt-8 relative flex h-full">
    <div class="flex-1 overflow-y-scroll h-full">
      <div class="text-blue uppercase font-title text-xl ml-4 mt-4 font-bold">
        {{ $t('app-versions.app-versions') }}
      </div>

      <l-card
        :title="$t('app-versions.release-channel')"
        :overflow-visible="true"
        :content-padding="false"
      >
        <l-select :options="channels" v-model="channel" class="px-8 pb-3" />
      </l-card>

      <l-card
        :title="$t('app-versions.active-versions')"
        :overflow-visible="true"
        :content-padding="false"
      >
        <table style="width: 100%">
          <thead>
            <tr>
              <th v-for="platform in platforms" :key="platform">
                {{ $t(`app-versions.${platform}`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="platform in platforms" :key="platform">
                {{
                  (activeVersions[channel] &&
                    activeVersions[channel][platform]) ||
                  '-'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </l-card>

      <l-card
        :title="$t('app-versions.app-versions')"
        :overflow-visible="false"
        :content-padding="false"
      >
        <table style="width: 100%">
          <thead>
            <tr>
              <th>{{ $t('app-versions.version') }}</th>
              <th
                v-for="platform in platforms"
                :key="platform"
                class="text-center"
              >
                {{ $t(`app-versions.${platform}`) }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(version, index) in filteredVersions" :key="index">
              <td>{{ version.name }}</td>
              <td
                v-for="platform in platforms"
                :key="platform"
                class="text-center"
              >
                <icon
                  class="m-auto"
                  :title="iconTitle(version, platform)"
                  :name="iconName(version, platform)"
                  :class="iconClass(version, platform)"
                />
              </td>
              <td class="align-right">
                <icon
                  name="settings"
                  @click="editVersion = version"
                  class="text-gray-300 cursor-pointer"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </l-card>
    </div>
    <side-panel :open="!!editVersion">
      <app-version-editor
        :version="editVersion"
        @close="editVersion = null"
        @disable="confirmDisable"
        @activate="confirmActivate"
      />
    </side-panel>
    <portal to="appRoot">
      <l-modal
        v-if="platformToDisable"
        :title="$t('app-versions.disable-version')"
        size="small"
        class="py-32 font-body"
        @close="platformToDisable = null"
      >
        <template #body>
          <div class="text-blue text-xl text-center uppercase">
            {{ $t('app-versions.disable-version-confirmation-message') }}
          </div>
          <l-modal-ctas>
            <l-modal-button
              type="secondary"
              :label="$t('app-versions.cancel')"
              @click.native="platformToDisable = null"
              class="flex-1 mr-4 bg-violet"
            />
            <l-modal-button
              type="adminMain"
              :label="$t('app-versions.disable-version')"
              @click.native="disablePlatform"
              class="flex-1"
            />
          </l-modal-ctas>
        </template>
      </l-modal>

      <l-modal
        v-if="platformToActivate"
        :title="$t('app-versions.activate-version')"
        size="small"
        class="py-32 font-body"
        @close="platformToActivate = null"
      >
        <template #body>
          <div class="text-blue text-xl text-center uppercase">
            {{ $t('app-versions.activate-version-confirmation-message') }}
          </div>
          <l-modal-ctas>
            <l-modal-button
              type="secondary"
              :label="$t('app-versions.cancel')"
              @click.native="platformToActivate = null"
              class="flex-1 mr-4 bg-violet"
            />
            <l-modal-button
              type="adminMain"
              :label="$t('app-versions.activate-version')"
              @click.native="activatePlatform"
              class="flex-1"
            />
          </l-modal-ctas>
        </template>
      </l-modal>
    </portal>
  </div>
</template>

<script>
import api from '@/api'
import AppVersionEditor from '@/components/AppVersionEditor.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LCard from '@last/core-ui/components/LCard.vue'
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import LSelect from '@last/core-ui/components/LSelect'
import SidePanel from '@last/core-ui/components/SidePanel.vue'

const ICON = {
  active: 'done',
  available: 'done',
  unavailable: 'end-shift',
  disabled: 'error'
}

const CLASS = {
  active: 'active',
  available: 'text-green',
  unavailable: 'text-gray-200',
  disabled: 'text-red'
}

const CHANNEL_RE = /^(?:\d+\.\d+\.\d+)(?:-([0-9a-zA-Z-]*)\.?.*)$/

export default {
  name: 'AppVersions',
  data() {
    return {
      platforms: ['windows', 'win32', 'bundle', 'android', 'ios', 'apk'],
      versions: [],
      channel: '',
      editVersion: null,
      platformToDisable: null,
      platformToActivate: null
    }
  },
  async mounted() {
    let response = await api.get('/support/versions')
    this.versions = response.data.versions
  },
  methods: {
    iconName(version, platform) {
      return ICON[version[platform] || 'unavailable']
    },
    iconClass(version, platform) {
      return CLASS[version[platform] || 'unavailable']
    },
    iconTitle(version, platform) {
      if (version[platform] === 'active') {
        return this.$t('app-versions.active')
      } else if (version[platform] === 'available') {
        return this.$t('app-versions.available')
      } else if (version[platform] === 'disabled') {
        return this.$t('app-versions.disabled')
      } else {
        return this.$t('app-versions.unavailable')
      }
    },
    confirmDisable(platform) {
      this.platformToDisable = platform
    },
    confirmActivate(platform) {
      this.platformToActivate = platform
    },
    async disablePlatform() {
      const version = this.editVersion
      const platform = this.platformToDisable
      version[platform] = 'disabled'
      this.platformToDisable = null
      try {
        await api.post('/support/versions', {
          action: 'disable',
          version: version.name,
          platform
        })
        this.$lnotification.create({
          title: this.$t('app-versions.version-disabled'),
          icon: 'done',
          iconColor: 'green'
        })
      } catch (error) {
        this.$lnotification.create({
          title: this.$t('app-versions.error-disabling-version'),
          icon: 'close',
          iconColor: 'red'
        })
        version[platform] = 'available'
      }
    },
    async activatePlatform() {
      const version = this.editVersion
      const platform = this.platformToActivate
      const channel = this.getChannel(version.name)
      const active = this.getActiveVersion(channel, platform)
      version[platform] = 'active'
      active[platform] = 'available'
      this.platformToActivate = null
      try {
        await api.post('/support/versions', {
          action: 'activate',
          version: version.name,
          platform
        })
        this.$lnotification.create({
          title: this.$t('app-versions.version-activated'),
          icon: 'done',
          iconColor: 'green'
        })
      } catch (error) {
        this.$lnotification.create({
          title: this.$t('app-versions.error-activating-version'),
          icon: 'close',
          iconColor: 'red'
        })
        version[platform] = 'available'
        active[platform] = 'active'
      }
    },
    getChannel(name) {
      const match = name.match(CHANNEL_RE)
      return match ? match[1] : ''
    },
    getActiveVersion(channel, platform) {
      const name = this.activeVersions[channel][platform]
      return this.versions.find(v => v.name === name)
    }
  },
  computed: {
    activeVersions() {
      const active = {}
      for (const version of this.versions) {
        const channel = this.getChannel(version.name)
        if (!active[channel]) {
          active[channel] = {}
        }
        for (const platform of this.platforms) {
          if (version[platform] === 'active' && !active[channel][platform]) {
            active[channel][platform] = version.name
          }
        }
      }
      return active
    },
    filteredVersions() {
      return this.versions.filter(v => this.getChannel(v.name) === this.channel)
    },
    channels() {
      return Object.keys(this.activeVersions)
        .sort()
        .map(c => ({ label: c ? c : 'Release', value: c }))
    }
  },
  components: {
    AppVersionEditor,
    Icon,
    LCard,
    LModal,
    LModalCtas,
    LModalButton,
    LSelect,
    SidePanel
  }
}
</script>

<style scoped>
.active {
  color: white;
  background-color: rgb(33, 190, 203);
  border-radius: 50%;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
</style>
