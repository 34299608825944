var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.open = false); }),expression:"() => (open = false)"}],staticClass:"w-full relative text-blue",class:{ 'opacity-50': _vm.disabled }},[_c('div',{staticClass:"w-full rounded-small leading-tight focus-within:border-lblue-500 flex items-center pr-3",class:{
      'bg-gray-100': _vm.theme === 'light',
      'border border-white': _vm.theme === 'transparent',
      'bg-transparent-gray': _vm.theme === 'dark',
      'border-red': _vm.redBorder,
      'my-2': _vm.withMargin,
      'cursor-pointer': !_vm.disabled
    },on:{"click":_vm.handleOpen}},[(_vm.icon)?_c('icon',{staticClass:"ml-4",class:_vm.iconClass,attrs:{"name":_vm.icon}}):_vm._e(),_c('div',{staticClass:"px-4 ellipsis",class:{
        'py-2 text-sm': _vm.small,
        'py-3': !_vm.small,
        'text-white': _vm.theme === 'dark',
        'w-full': _vm.arrowStyle !== 2,
        'pr-2': _vm.arrowStyle === 2
      }},[(_vm.addButtonSelected)?_c('div',{staticClass:"text-violet flex flex-row items-center"},[_c('icon',{attrs:{"small":"","name":"plus"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.addButtonText))])],1):_c('div',[_vm._v(" "+_vm._s(_vm.text)+" ")])]),(!_vm.canRemoveSelection)?_c('icon',{staticClass:"arrow",class:{
        rotated: _vm.open,
        'text-gray-400': _vm.arrowStyle === 2,
        white: _vm.arrowStyle !== 2 && _vm.theme === 'transparent',
        'text-accent': _vm.arrowStyle !== 2
      },attrs:{"name":_vm.arrowStyle === 2 ? 'arrow-down-2' : 'arrow-down'}}):(_vm.canRemoveSelection)?_c('icon',{staticClass:"arrow text-violet",attrs:{"name":"close"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.remove.apply(null, arguments)}}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.open)?_c('div',{staticClass:"rounded shadow-md py-2 options absolute left-0 right-0 bg-white z-10 text-blue scrollable",class:{
        'bottom-0 mb-12 top': _vm.position === 'top' || _vm.shouldGoTop,
        bottom: _vm.position === 'bottom',
        'border border-gray-200': _vm.theme === 'light',
        'border border-gray-300 w-5/6 ml-5': _vm.arrowStyle === 2,
        'bg-blue text-white': _vm.theme === 'dark'
      }},[(_vm.queryInput)?_c('l-input',{staticClass:"input-class",attrs:{"placeholder":_vm.placeholder,"icon":"search","icon-class":"text-gray-400","small":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e(),_c('div',{staticClass:"px-4 py-3 cursor-pointer text-sm",class:{
          'bg-blue text-white': _vm.theme === 'dark'
        }},[(_vm.selectAll)?_c('l-checkbox',{staticClass:"px-4 py-3 text-gray-700 text-base",class:{ 'hover:bg-gray-200': _vm.theme === 'light' },attrs:{"small":"","value":_vm.allSelected,"half":!!(_vm.selected.length && !_vm.allSelected),"label":_vm.$t('core.select-all')},on:{"input":_vm.selectAllOptions}}):_vm._e(),_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:_vm.key(option)},[_c('div',{staticClass:"px-4 py-3",class:{
              'hover:bg-gray-200': _vm.theme === 'light'
            },on:{"click":function($event){return _vm.select(option)}}},[(_vm.multiselect)?_c('l-checkbox',{staticClass:"mr-2",class:{
                'text-base text-gray-700': _vm.hasChildren(option)
              },attrs:{"small":"","value":_vm.isSelected(option) > 0 ||
                (_vm.hasSelectedChildren(option) &&
                  !_vm.hasUnselectedChildren(option)),"label":option[_vm.optionLabel],"half":_vm.isSelected(option) == 2 ||
                (_vm.hasSelectedChildren(option) && _vm.hasUnselectedChildren(option))},on:{"input":function($event){return _vm.select(option)}}}):_c('div',[_vm._v(_vm._s(option[_vm.optionLabel]))])],1),(_vm.hasChildren(option))?_c('div',_vm._l((option.children),function(nestedOption){return _c('div',{key:_vm.key(nestedOption)},[_c('div',{staticClass:"pr-4 py-2 pl-8",class:{
                  'hover:bg-gray-200': _vm.theme === 'light'
                },on:{"click":function($event){return _vm.select(nestedOption)}}},[(_vm.multiselect)?_c('l-checkbox',{attrs:{"small":"","value":_vm.isSelected(nestedOption) > 0,"label":nestedOption[_vm.optionLabel],"half":_vm.isSelected(nestedOption) == 2},on:{"input":function($event){return _vm.select(nestedOption)}}}):_c('div',[_vm._v(_vm._s(nestedOption[_vm.optionLabel]))])],1)])}),0):_vm._e()])}),(_vm.addButton)?_c('div',{staticClass:"px-4 py-3 text-violet flex flex-row items-center",on:{"click":function () {
              _vm.addButtonSelected = true
              _vm.open = false
              _vm.$emit('add-button-selected')
            }}},[_c('icon',{attrs:{"small":"","name":"plus"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.addButtonText))])],1):_vm._e()],2)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }