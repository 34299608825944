<template>
  <side-panel-view @close="$emit('close')">
    <l-field :label="$t('devices.name')" v-if="editingDevice">
      <l-input v-model="editingDevice.name" theme="dark" />
    </l-field>
    <div class="flex flex-row">
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.platform') }}
        </div>
        <div>
          {{ device.platform }}
        </div>
      </div>
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.os-version') }}
        </div>
        <div>
          {{ device.osVersion }}
        </div>
      </div>
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.model') }}
        </div>
        <div>
          {{ device.model }}
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-5">
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.app-version') }}
        </div>
        <div>
          {{ device.appVersion }}
        </div>
      </div>
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.creation-time') }}
        </div>
        <div>
          {{ device.creationTime | date }}
        </div>
      </div>
      <div class="flex-1">
        <div class="text-gray-400">
          {{ $t('devices.update-time') }}
        </div>
        <div>
          {{ device.updateTime | date }}
        </div>
      </div>
    </div>
    <template slot="footer">
      <button
        @click="save"
        class="mt-6 py-2 px-10 border rounded-xs uppercase text-sm bg-violet text-white border-violet transition"
      >
        {{ $t('devices.save') }}
      </button>
    </template>
  </side-panel-view>
</template>

<script>
import SidePanelView from '@last/core-ui/components/SidePanelView.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'

export default {
  name: 'DeviceEditor',
  components: {
    SidePanelView,
    LInput,
    LField
  },
  data() {
    return {
      editingDevice: null
    }
  },
  mounted() {
    if (this.device) {
      this.editingDevice = { ...this.device }
    }
  },
  props: {
    device: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    save() {
      this.$emit('updateDevice', this.editingDevice)
    }
  }
}
</script>

<style scoped></style>
