<template>
  <l-card :title="$t('customer-id.title')">
    <div class="flex">
      <div class="text-blue flex-1">{{ $t('customer-id.label') }}:</div>
      <div class="text-violet">{{ value }}</div>
    </div>
  </l-card>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'

export default {
  name: 'LocationCustomerId',
  components: {
    LCard
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
