<template>
  <div class="text-sm" :class="{ 'bg-gray-100 -mx-16': large }">
    <div class="separator bg-gray-200 -mx-16" />
    <div
      class="flex flex-row py-2"
      :class="{
        'mx-16': large,
        'items-baseline': configParams.length > 1,
        'items-center': configParams.length <= 1
      }"
    >
      <div class="w-1/4 flex flex-col" v-if="large">
        <div class="w-10/12">
          <l-editable-value
            :label="'Name'"
            v-model="params.name"
            @input="sendUpdate"
            :placeholder="$t('inputs.editable-content-placeholder')"
          />
        </div>
        <div
          class="flex w-10/12 py-3"
          v-if="integration.linkedTo === 'locationBrand'"
        >
          <div class="mr-2 text-gray-400">
            {{ $t('integrations.brand-name') }}:
          </div>
          <div>
            {{ integration.brandName }}
          </div>
        </div>
      </div>
      <div class="w-1/4 text-base" v-else>{{ integration.companyName }}</div>
      <div class="flex-1">
        <div v-if="editableParamsLength > 0" class="flex flex-col">
          <div v-for="param in configParams" :key="param.key" class="mr-4">
            <div
              class="flex items-center"
              v-if="param.type === 'string' && !param.disabled"
            >
              <l-editable-value
                :label="param.name"
                v-model="params[param.key]"
                @input="sendUpdate"
                :placeholder="$t('inputs.editable-content-placeholder')"
              />
            </div>
            <div
              class="flex items-center py-3"
              v-if="param.type === 'string' && param.disabled"
            >
              <div class="mr-2 text-gray-400">{{ param.name }}:</div>
              <div>
                {{ params[param.key] && params[param.key].substr(0, 15) }}...
              </div>
              <icon
                name="copies"
                class="text-violet cursor-pointer ml-2"
                @click="handleCopy(params[param.key])"
              />
            </div>
            <div
              class="flex items-center py-3"
              v-else-if="param.type === 'boolean'"
            >
              <div>
                <l-checkbox
                  v-model="params[param.key]"
                  :label="param.name"
                  class="mr-2"
                  small
                  @input="sendUpdate"
                />
              </div>
            </div>
            <div
              v-else-if="param.type === 'select'"
              class="flex max-w-xs items-center py-3"
            >
              <div class="text-gray-400 mr-2">{{ param.name }}:</div>
              <l-select
                v-model="params[param.key]"
                :options="param.options"
                @input="sendUpdate"
                small
              />
            </div>
          </div>
          <div class="flex flex-row items-center py-3" v-if="devMode">
            <div class="text-gray-400 mr-2">
              {{ $t('integrations.location-virtual-brand-id') }}:
            </div>
            <div>{{ integration.locationVirtualBrandId.substr(0, 15) }}...</div>
            <icon
              name="copies"
              class="text-violet cursor-pointer ml-2"
              @click.native.stop="
                handleCopy(integration.locationVirtualBrandId)
              "
            />
          </div>
        </div>
      </div>
      <div class="flex w-1/4">
        {{ lastCatalogUpdateTime }}
        <icon v-if="catalogSentWithErrors" name="alert" class="ml-2 text-red" />
        <icon
          v-if="catalogSentWithoutErrors"
          name="done"
          class="text-green ml-2"
        />
      </div>
      <div class="flex items-center">
        <l-switch
          class="w-1/4 mr-3"
          v-model="localIntegrationEnabled"
          :disabled="!paramsFilled"
          @input="sendUpdate"
        />
        <icon
          name="trash"
          class="cursor-pointer text-gray-400"
          @click="deleteIntegration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LSwitch from '@last/core-ui/components/LSwitch'
import Icon from '@last/core-ui/components/Icon'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LSelect from '@last/core-ui/components/LSelect'
import LEditableValue from '@last/core-ui/components/LEditableValue.vue'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'IntegrationRow',
  components: {
    LSwitch,
    LSelect,
    Icon,
    LCheckbox,
    LEditableValue
  },
  data() {
    return {
      editing: false,
      localIntegrationEnabled: false,
      params: {}
    }
  },
  props: {
    idToEdit: {
      type: String,
      default: null
    },
    integration: {
      type: Object,
      default: () => {}
    },
    large: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.editingId = this.idToEdit
    this.localIntegrationEnabled = this.integration.enabled
    this.fillParams()
  },
  methods: {
    async handleCopy(text) {
      await navigator.clipboard.writeText(text)
      this.$lnotification.create({
        title: this.$t('inputs.copied-clipboard'),
        icon: 'check',
        iconColor: 'green'
      })
    },
    fillParams() {
      let data = this.integration || {}
      this.params = this.integration.supportParams.reduce((res, param) => {
        if (param.type !== 'boolean') {
          res[param.key] =
            data[param.key] || (data.metadata && data.metadata[param.key])
        } else {
          res[param.key] =
            !!data[param.key] || (data.metadata && !!data.metadata[param.key])
        }

        return res
      }, {})
    },
    cancelEdit() {
      this.editingId = this.idToEdit
      this.editing = false
    },
    sendUpdate() {
      this.$emit('update', {
        ...this.integration,
        enabled: this.localIntegrationEnabled,
        params: this.turnBooleanIntoNumber()
      })
      this.editing = false
    },
    deleteIntegration() {
      this.$emit('delete', this.integration)
    },
    turnBooleanIntoNumber() {
      let formattedParams = {}
      for (let param in this.params) {
        if (typeof this.params[param] === 'boolean')
          formattedParams[param] = this.params[param] ? 1 : 0
        else formattedParams[param] = this.params[param]
      }
      return formattedParams
    }
  },
  computed: {
    ...mapState('auth', ['devMode']),
    configParams() {
      return this.integration.supportParams.filter(p => p.key !== 'name')
    },
    paramsFilled() {
      return Object.values(this.integration.supportParams)
        .filter(param => !param.disabled && param.type === 'string')
        .every(param => !!this.params[param.key])
    },
    editableParamsLength() {
      return Object.values(this.integration.supportParams).filter(
        param => param.type === 'string'
      ).length
    },
    lastCatalogUpdateTime() {
      let lastUpdatedInfo = this.integration.lastUpdatedInfo
      if (lastUpdatedInfo) {
        if (lastUpdatedInfo.sendingTime) {
          return moment(lastUpdatedInfo.sendingTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          // If last catalog update time hasn't been pushed yet, we show expected time
          return moment(lastUpdatedInfo.updateTime)
            .add(lastUpdatedInfo.waitingTimeSeconds, 'seconds')
            .format('YYYY-MM-DD HH:mm:ss')
        }
      }
      return null
    },
    catalogSentWithoutErrors() {
      return (
        this.integration.lastUpdatedInfo?.sendingTime &&
        !this.integration.lastUpdatedInfo?.errorTime
      )
    },
    catalogSentWithErrors() {
      return this.integration.lastUpdatedInfo?.errorTime
    }
  },
  watch: {
    integration() {
      this.localIntegrationEnabled = this.integration.enabled
      this.fillParams()
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
