var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full border-gray-300 rounded-small focus-within:border-blue flex items-center",class:[
      _vm.textColorClass,
      {
        'border-red': _vm.wrongValue,
        'hover:border-red': _vm.wrongValue,
        'focus-within:border-red': _vm.wrongValue,
        'my-2': _vm.verticalMargin,
        'bg-white border': _vm.theme === 'light',
        'bg-gray-100': _vm.theme === 'semi',
        'bg-transparent-gray': _vm.theme === 'dark',
        'opacity-50': _vm.disabled,
        'hover:border-gray-400': !_vm.disabled,
        'h-full': _vm.inputType === 'textarea'
      }
    ]},[_vm._t("default",function(){return [((_vm.type)==='checkbox'&&(_vm.inputType === 'input'))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",staticClass:"appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight",class:{
          'py-2 text-sm': _vm.small,
          'py-4': _vm.big,
          'py-8': _vm.giant,
          'py-3': !_vm.small && !_vm.big && !_vm.giant && !_vm.maskIt,
          'bg-transparent text-white': _vm.theme === 'dark',
          'py-3 tracking-widest': _vm.maskIt
        },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.computedValue)?_vm._i(_vm.computedValue,null)>-1:(_vm.computedValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"change":function($event){var $$a=_vm.computedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedValue=$$c}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio'&&(_vm.inputType === 'input'))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",staticClass:"appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight",class:{
          'py-2 text-sm': _vm.small,
          'py-4': _vm.big,
          'py-8': _vm.giant,
          'py-3': !_vm.small && !_vm.big && !_vm.giant && !_vm.maskIt,
          'bg-transparent text-white': _vm.theme === 'dark',
          'py-3 tracking-widest': _vm.maskIt
        },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.computedValue,null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"change":function($event){_vm.computedValue=null}}},'input',_vm.$attrs,false)):(_vm.inputType === 'input')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",staticClass:"appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight",class:{
          'py-2 text-sm': _vm.small,
          'py-4': _vm.big,
          'py-8': _vm.giant,
          'py-3': !_vm.small && !_vm.big && !_vm.giant && !_vm.maskIt,
          'bg-transparent text-white': _vm.theme === 'dark',
          'py-3 tracking-widest': _vm.maskIt
        },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":_vm.type},domProps:{"value":(_vm.computedValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"input":function($event){if($event.target.composing){ return; }_vm.computedValue=$event.target.value}}},'input',_vm.$attrs,false)):(_vm.inputType === 'textarea')?_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",staticClass:"appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight",class:{
          'py-2 text-sm': _vm.small,
          'py-4': _vm.big,
          'py-8': _vm.giant,
          'py-3': !_vm.small && !_vm.big && !_vm.giant,
          'bg-transparent text-white': _vm.theme === 'dark',
          'bg-gray-100': _vm.theme === 'semi',
          'text-blue': _vm.theme === 'light'
        },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"maxlength":_vm.maxLength},domProps:{"value":(_vm.computedValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.computedValue=$event.target.value}}},'textarea',_vm.$attrs,false)):_vm._e(),_vm._v(" "),_vm._t("icons",function(){return [_c('div',{staticClass:"h-full rounded-small flex items-center"},[(_vm.icon)?_c('icon',{staticClass:"mr-4",class:_vm.iconClass,attrs:{"name":_vm.icon}}):_vm._e(),(_vm.wrongValue)?_c('icon',{staticClass:"mr-4 text-red",attrs:{"name":"alert"}}):_vm._e()],1)]}),(!_vm.icon && _vm.endPlaceholder)?_c('div',{staticClass:"h-full flex items-center"},[_c('div',{staticClass:"mr-4",class:'text-' + (_vm.endPlaceholderColor || 'gray-400')},[_vm._v(" "+_vm._s(_vm.endPlaceholder)+" ")])]):_vm._e()]})],2),(_vm.wrongValue && _vm.errorMessage)?_c('div',{staticClass:"text-xs text-red ml-4"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }