<template>
  <div class="px-8 py-12">
    <card :title="$t('deleted-locations.title')" class="text-gray-500">
      <div class="mb-6 -mt-5">
        <l-input
          class="max-w-lg"
          v-model="query"
          icon="search"
          icon-class="text-blue"
        />
      </div>
      <div class="flex flex-row border-b border-gray-200 text-sm pb-2 gap-2">
        <div class="w-4/12">{{ $t('deleted-locations.name') }}</div>
        <div class="w-2/12">{{ $t('deleted-locations.id') }}</div>
        <div class="w-4/12">{{ $t('deleted-locations.address') }}</div>
        <div class="w-2/12">
          {{ $t('deleted-locations.deletion-date') }}
        </div>
        <div class="w-6"></div>
        <div class="w-6"></div>
      </div>
      <div
        v-for="(organization, index) in Object.keys(organizations)"
        :key="index"
        class="mb-10"
      >
        <div class="uppercase mt-5">{{ organization }}</div>
        <div
          v-for="location in organizations[organization]"
          :key="location.id"
          class="flex flex-row border-b border-gray-200 py-3 items-center hover:bg-gray-100 gap-2"
        >
          <div class="w-4/12 text-blue">{{ location.name }}</div>
          <div class="w-2/12">{{ shortLocationId(location.id) }}</div>
          <div class="w-4/12">{{ location.address }}</div>
          <div class="w-2/12">{{ location.deletionTimeFormatted }}</div>
          <l-tool-tip
            :message="$t('deleted-locations.recover-temporally')"
            :position="'left'"
          >
            <icon
              name="clock-counter-clockwise"
              class="w-6 h-6 text-violet"
              @click="recoverLocation({ location, temporally: true })"
            />
          </l-tool-tip>
          <l-tool-tip
            :message="$t('deleted-locations.recover')"
            :position="'left'"
          >
            <icon
              name="loading"
              class="w-6 h-6 text-violet"
              :class="{
                'pointer-events-none opacity-50': !location.allowRecover
              }"
              @click="recoverLocation({ location, temporally: false })"
            />
          </l-tool-tip>
        </div>
      </div>
    </card>
    <div
      v-if="recoveringLocation"
      class="bg-black bg-opacity-25 absolute top-0 left-0 w-full h-full z-30 flex content-center"
    >
      <l-modal @close="recoveringLocation = null" hidden-close>
        <template #body>
          <recover-location-pop-up
            :location="recoveringLocation"
            :temporally="recoveringLocationTemporally"
            @cancel="recoveringLocation = null"
            @recovered="locationRecovered"
          />
        </template>
      </l-modal>
    </div>
  </div>
</template>

<script>
import Card from '@last/core-ui/components/LCard.vue'
import LModal from '@last/core-ui/components/LModal.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import RecoverLocationPopUp from '@/components/RecoverLocationPopUp.vue'
import Vue from 'vue'
import LToolTip from '@last/core-ui/components/LToolTip.vue'
import api from '@/api'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'DeletedLocations',
  props: {},
  data() {
    return {
      deletedLocationsByOrganization: {},
      recoveringLocation: null,
      recoveringLocationTemporally: false,
      loading: false,
      query: ''
    }
  },
  async mounted() {
    let response = await api.get('/support/locations/deleted')
    this.deletedLocationsByOrganization = response.data.reduce(
      (organizations, location) => {
        if (!organizations[location.organizationName]) {
          organizations[location.organizationName] = []
        }
        organizations[location.organizationName].push(location)
        return organizations
      },
      {}
    )
  },
  methods: {
    deletionTime(deletionTime) {
      if (!deletionTime) return '-'
      return moment(deletionTime).format('DD/MM/YYYY · HH:mm')
    },
    shortLocationId(locationId) {
      return locationId.substring(0, 8) + '...'
    },
    recoverLocation({ location, temporally }) {
      this.recoveringLocation = location
      this.recoveringLocationTemporally = temporally
    },
    locationRecovered() {
      const organizationName = this.recoveringLocation.organizationName
      const locationId = this.recoveringLocation.id

      const newDeletedLocations = this.deletedLocationsByOrganization[
        organizationName
      ].filter(location => location.id !== locationId)

      if (newDeletedLocations.length === 0) {
        Vue.delete(this.deletedLocationsByOrganization, organizationName)
      } else {
        Vue.set(
          this.deletedLocationsByOrganization,
          organizationName,
          newDeletedLocations
        )
      }
      this.recoveringLocation = null
    }
  },
  computed: {
    ...mapState('auth', ['devMode']),
    filteredLocationsByOrganization() {
      if (this.query === '') return this.deletedLocationsByOrganization
      return Object.keys(this.deletedLocationsByOrganization).reduce(
        (acc, organizationName) => {
          if (
            organizationName.toLowerCase().includes(this.query.toLowerCase())
          ) {
            acc[organizationName] =
              this.deletedLocationsByOrganization[organizationName]
          } else {
            const locations = this.deletedLocationsByOrganization[
              organizationName
            ].filter(location =>
              location.name.toLowerCase().includes(this.query.toLowerCase())
            )
            if (locations.length > 0) {
              acc[organizationName] = locations
            }
          }
          return acc
        },
        {}
      )
    },
    organizations() {
      return Object.keys(this.filteredLocationsByOrganization).reduce(
        (acc, organizationName) => {
          acc[organizationName] = this.filteredLocationsByOrganization[
            organizationName
          ].map(location => ({
            ...location,
            deletionTimeFormatted: moment(location.deletionTime)?.format(
              'L hh:mm:ss'
            ),
            allowRecover:
              this.devMode ||
              (!!location.deletionTime &&
                moment(location.deletionTime)?.isAfter(moment('2024-01-01'))) //arbitrary date to forbid recover pre pricing migration locations
          }))
          return acc
        },
        {}
      )
    }
  },
  components: {
    Card,
    Icon,
    LInput,
    RecoverLocationPopUp,
    LModal,
    LToolTip
  }
}
</script>
