<template>
  <div class="flex flex-row h-screen">
    <div
      class="w-full md:w-1/2 flex justify-center items-center flex-1 bg-gray-100"
    >
      <l-card class="w-3/4 md:w-1/2 xl:max-w-md flex flex-col">
        <div class="text-blue title uppercase text-2xl">
          {{ $t('login.title') }}
        </div>
        <l-field :label="$t('inputs.email-label')">
          <l-input
            type="text"
            :placeholder="$t('inputs.email-placeholder')"
            v-model="email"
            icon="mail"
            icon-class="text-violet"
            @enter="startLogin"
          />
        </l-field>
        <l-field :label="$t('inputs.password-label')">
          <l-input-password
            :placeholder="$t('inputs.password-placeholder')"
            v-model="password"
            icon-class="text-violet"
            @enter="startLogin"
          />
        </l-field>
        <section :class="{ 'opacity-0': !error }" class="pb-2 flex">
          <p class="text-red">{{ error }}</p>
        </section>
        <div class="flex justify-center md:justify-end">
          <l-button
            class="text-white bg-violet border-violet-b shadow rounded-lg uppercase"
            :class="{ 'is-loading': loggingIn, 'opacity-50': !isComplete }"
            @click="startLogin"
          >
            {{ $t('login.title') }}
          </l-button>
        </div>
      </l-card>
    </div>
    <div class="bg-blue-600 w-1/3 flex">
      <div class="w-1/3 m-auto">
        <img
          src="../assets/logo-new-white.png"
          class="py-2 sm:py-3 my-1 w-full h-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LField from '@last/core-ui/components/LField'
import LInput from '@last/core-ui/components/LInput'
import LInputPassword from '@last/core-ui/components/LInputPassword'
import LButton from '@last/core-ui/components/LButton'
import LCard from '@last/core-ui/components/LCard'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    LField,
    LInputPassword,
    LButton,
    LInput,
    LCard
  },
  data() {
    return {
      email: null,
      password: null,
      loggingIn: false,
      error: null
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'global' })
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'refreshCurrentUser']),
    async startLogin() {
      if (!this.isComplete) return
      this.loggingIn = true
      try {
        await this.login({ email: this.email, password: this.password })
      } catch (error) {
        this.error = this.$t('login.error')
      }
      this.loggingIn = false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    isComplete() {
      return this.email && this.password
    }
  },
  watch: {
    async isAuthenticated(authenticated) {
      if (authenticated) {
        await this.refreshCurrentUser()
        let url = this.$route.query.redirect
        if (url) {
          this.$router.push({ path: url })
        } else {
          this.$router.push({ name: 'global' })
        }
      }
    }
  }
}
</script>

<style scoped></style>
