import { render, staticRenderFns } from "./LCard.vue?vue&type=template&id=69361a53&scoped=true&"
import script from "./LCard.vue?vue&type=script&lang=js&"
export * from "./LCard.vue?vue&type=script&lang=js&"
import style0 from "./LCard.vue?vue&type=style&index=0&id=69361a53&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../support/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69361a53",
  null
  
)

export default component.exports