import api from '@/api.js'
import store from '@/store/index.js'
import router from '@/router.js'
import i18n from '@/i18n'

window.addEventListener('storage', e => {
  if (e.key === 'accessToken' && !localStorage.getItem('accessToken')) {
    store.dispatch('auth/logout', e.newValue)
  }
})

const state = {
  accessToken: localStorage.getItem('accessToken'),
  user: {
    permissions: {}
  },
  devMode: false
}

const getters = {
  isAuthenticated: state => !!state.accessToken,
  rootUser: state => state.user,
  permissions: state => state.user.permissions
}

const actions = {
  async login({ commit }, loginData) {
    let response = await api.post('/support/users/login', loginData)
    commit('setAccessToken', response.data.token)
  },
  async logout({ commit }) {
    await api.post('/users/logout')
    commit('logout')
  },
  async refreshCurrentUser({ commit }) {
    let response
    let user
    try {
      response = await api.get('/users/me')
      user = response.data
      window.pagesense = window.pagesense || []
      window.pagesense.push([
        'trackUser',
        { id: user.id, name: user.name, email: user.email }
      ])
      window.pagesense.push(['identifyUser', `${user.name} <${user.email}>`])
      if (router.currentRoute.query.translate === 'true') {
        i18n.locale = ''
        i18n.fallbackLocale = ''
      }
    } catch (e) {
      commit('logout')
      router.push({ name: 'login' })
    }

    commit('refreshCurrentUser', user)
  },
  setDevMode({ commit }, devMode) {
    commit('setDevMode', devMode)
  }
}

const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token
    localStorage.setItem('accessToken', token)
  },
  refreshCurrentUser(state, user) {
    user.permissions = user.permissions
      ? user.permissions.reduce((acc, permission) => {
          acc[permission] = true
          return acc
        }, {})
      : {}
    state.user = user
  },
  logout(state) {
    state.accessToken = null
    state.user = {}
    localStorage.clear()
  },
  setDevMode(state, devMode) {
    state.devMode = devMode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
