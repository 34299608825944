<template>
  <div class="relative">
    <l-upload
      @input="uploadFile"
      :disabled="disabled"
      :theme="theme"
      :drag-drop="dragDrop"
      :circle="circle"
    >
      <l-loading-spinner v-if="loading" />
      <div
        class="w-full h-full bg-contain bg-no-repeat bg-center rounded-small overflow-hidden"
        :class="{ 'rounded-full': circle }"
        v-else-if="imageUrl"
        :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
      ></div>
    </l-upload>
    <div
      v-if="value && isRemovable"
      class="absolute top-0 right-0 rounded-full w-7 h-7 m-2 bg-gray-100 flex flex-row items-center justify-center cursor-pointer"
      @click="$emit('remove')"
    >
      <icon name="close" class="text-gray-400" small />
    </div>
  </div>
</template>

<script>
import LUpload from '@last/core-ui/components/LUpload.vue'
import LLoadingSpinner from './LLoadingSpinner.vue'
import axios from 'axios'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'LImageUpload',
  props: {
    dragDrop: {
      type: Boolean,
      default: false
    },
    isRemovable: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      thumbnailWidth: null,
      thumbnailHeight: null
    }
  },
  mounted() {
    this.thumbnailWidth = this.$el.clientWidth
    this.thumbnailHeight = this.$el.clientHeight
  },
  methods: {
    async uploadFile(file) {
      if (this.loading) return
      this.loading = true
      let cloudName = 'lastpos'
      let unsignedUploadPreset = 'unsigned-preset'
      var url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
      let formData = new FormData()
      formData.append('file', file)
      formData.append('upload_preset', unsignedUploadPreset)
      let response = await axios.post(url, formData)
      this.$emit('input', response.data.public_id)
      this.loading = false
    }
  },
  computed: {
    imageUrl() {
      if (
        this.value === null ||
        this.thumbnailWidth === null ||
        this.thumbnailHeight === null
      )
        return null
      return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto,w_${this.thumbnailWidth},h_${this.thumbnailHeight}/${this.value}`
    }
  },
  components: {
    LUpload,
    LLoadingSpinner,
    Icon
  }
}
</script>
