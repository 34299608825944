<template>
  <div>
    <l-card :title="$t('devices.device-management')">
      <l-field
        v-if="devices && devices.length > 0"
        :label="$t('devices.master-device')"
        class="w-1/3 pb-10"
      >
        <l-select
          v-model="masterDeviceId"
          @input="saveMaster"
          :options="devicesWithLabel"
          option-label="label"
          option-value="id"
        />
      </l-field>
      <div class="flex flex-row text-gray-400 mb-8">
        <div class="flex-1">{{ $t('devices.name-and-id') }}</div>
        <div class="w-1/12">{{ $t('devices.platform') }}</div>
        <div class="w-2/12">{{ $t('devices.app-version') }}</div>
        <div class="flex-1">{{ $t('devices.update-time') }}</div>
        <div class="flex-1">{{ $t('devices.mode') }}</div>
        <div class="w-1/12"></div>
      </div>

      <div class="separator bg-gray-200 mt-5 -mx-8" />

      <div v-for="device in devices" :key="device.id">
        <div class="flex flex-row mt-5 text-blue">
          <div class="flex-1" v-if="device.name">
            {{ device.name }}
          </div>
          <div class="flex-1" v-else>{{ device.id.substring(0, 8) }}...</div>
          <div class="w-1/12 text-gray-400">{{ device.platform }}</div>
          <div class="w-2/12 text-gray-400">{{ device.appVersion }}</div>
          <div class="flex-1">{{ device.updateTime | date }}</div>
          <div class="flex-1">
            <div v-if="device.mode === 'master'" class="text-violet">
              {{ $t('devices.master') }}
            </div>
          </div>
          <div class="flex flex-row text-gray-400 w-1/12 justify-end">
            <icon
              name="eye"
              class="cursor-pointer"
              @click="deviceToEdit = device"
            />
            <icon
              v-if="device.mode !== 'master'"
              name="close"
              class="cursor-pointer ml-3"
              @click="deviceToDelete = device"
            />
          </div>
        </div>
        <div class="separator bg-gray-200 mt-5 -mx-8" />
      </div>
    </l-card>
    <portal to="appRoot">
      <l-modal
        v-if="deviceToDelete"
        :title="$t('devices.remove-device')"
        size="small"
        class="py-32 font-body"
        @close="deviceToDelete = null"
      >
        <template slot="body">
          <div class="text-blue text-xl text-center uppercase">
            {{ $t('devices.remove-confirmation-message') }}
          </div>
          <l-modal-ctas>
            <l-modal-button
              type="secondary"
              :label="$t('devices.cancel')"
              @click.native="deviceToDelete = null"
              class="flex-1 mr-4 bg-violet"
            />
            <l-modal-button
              type="adminMain"
              :label="$t('devices.remove-device')"
              @click.native="deleteDevice"
              class="flex-1"
            />
          </l-modal-ctas>
        </template>
      </l-modal>
    </portal>
    <portal to="locationSettingsPortal">
      <side-panel :open="!!deviceToEdit">
        <device-editor
          @close="deviceToEdit = false"
          :device="deviceToEdit"
          @updateDevice="device => updateDevice(device)"
        />
      </side-panel>
    </portal>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import Icon from '@last/core-ui/components/Icon'
import LSelect from '@last/core-ui/components/LSelect.vue'
import LField from '@last/core-ui/components/LField.vue'
import DeviceEditor from './DeviceEditor.vue'
import SidePanel from '@last/core-ui/components/SidePanel.vue'
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import { mapState } from 'vuex'
import api from '@/api.js'

export default {
  name: 'DevicesSettings',
  components: {
    LCard,
    Icon,
    LSelect,
    LField,
    LModal,
    LModalCtas,
    LModalButton,
    SidePanel,
    DeviceEditor
  },
  data() {
    return {
      masterDeviceId: null,
      deviceToDelete: null,
      deviceToEdit: null
    }
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    if (this.devices) {
      this.masterDeviceId = this.devices.find(
        device => device.mode === 'master'
      ).id
    }
  },
  methods: {
    async saveMaster() {
      await api.put(
        `/support/location/${this.selectedLocation.id}/devices/updateMaster`,
        { masterDeviceId: this.masterDeviceId }
      )
      this.$emit('refreshDevices')
    },
    async deleteDevice() {
      if (this.deviceToDelete.mode !== 'master') {
        await api.delete(
          `/support/location/${this.selectedLocation.id}/devices/${this.deviceToDelete.id}`
        )
        this.$emit('refreshDevices')
      }
      this.deviceToDelete = null
    },
    async updateDevice(device) {
      await api.put(
        `/support/location/${this.selectedLocation.id}/devices/${device.id}/update`,
        { device }
      )
      this.$emit('refreshDevices')
      this.deviceToEdit = null
    }
  },
  computed: {
    ...mapState('organizations', ['selectedLocation']),
    devicesWithLabel() {
      if (!this.devices) {
        return []
      }
      return this.devices.map(device => {
        return {
          ...device,
          label: device.name || device.id
        }
      })
    }
  },
  watch: {
    devices() {
      if (this.devices) {
        this.masterDeviceId = this.devices.find(
          device => device.mode === 'master'
        )?.id
      }
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
