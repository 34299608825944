<template>
  <div class="relative">
    <div
      @click.stop="show = true"
      v-click-outside="() => (show = false)"
      @mouseover="show = true"
      @mouseleave="show = false"
      class="flex flex-row items-center relative"
    >
      <slot> </slot>
      <div
        v-if="show"
        :class="{
          'tooltip-box-right': position === 'right',
          'tooltip-box-left': position === 'left',
          'tooltip-box-small': size === 'small',
          'tooltip-box-full-width': size === 'full-width'
        }"
        class="absolute bg-blue-700 text-white rounded-lg border-blue-700 font-body text-sm ml-3 py-2 px-3 z-50"
      >
        <div
          :class="{ 'tooltip-box-message-full-width': size === 'full-width' }"
          class="tooltip-message"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LToolTip',
  props: {
    message: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'right'
    },
    size: {
      type: String,
      default: 'full-width'
    }
  },
  data() {
    return {
      show: false
    }
  }
}
</script>

<style scoped>
.tooltip-box-right::after {
  content: ' ';
  position: absolute;
  top: 45%;
  right: 100%; /* To the right of the tooltip */
  margin-bottom: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #1d1c48 transparent transparent;
}

.tooltip-box-right {
  /* Position the tooltip */
  left: 100%;
}

.tooltip-box-left::after {
  content: ' ';
  position: absolute;
  top: 45%;
  left: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent #1d1c48;
}

.tooltip-box-left {
  /* Position the tooltip */
  right: calc(100% + 8px);
}

.tooltip-box-small {
  width: 170px;
}

.tooltip-box-full-width {
  @apply h-10;
}

.tooltip-box-message-full-width {
  white-space: nowrap;
}
</style>
