<template>
  <div class="h-screen flex flex-col overflow-hidden relative">
    <top-bar />
    <div class="flex h-full bg-gray-100 overflow-hidden">
      <div class="h-full relative z-20 hidden sm:block">
        <settings-menu v-if="isSettingsRoute" />
        <side-menu v-else />
      </div>
      <div class="layout-content overflow-x-hidden h-full">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from './TopBar.vue'
import { mapActions, mapGetters } from 'vuex'
import SideMenu from '@/components/SideMenu.vue'
import SettingsMenu from '@/components/SettingsMenu.vue'

export default {
  name: 'Support',
  data() {
    return {
      menuExpanded: false
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.refreshCurrentUser()
      this.refreshOrganizations()
      this.refreshIntegrations()
    }
  },
  methods: {
    ...mapActions('auth', ['refreshCurrentUser']),
    ...mapActions('organizations', ['refreshOrganizations']),
    ...mapActions('integrations', ['refreshIntegrations'])
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    isSettingsRoute() {
      return (
        this.$route.path.includes('/settings/') ||
        this.$route.name.includes('settings')
      )
    }
  },
  components: {
    TopBar,
    SideMenu,
    SettingsMenu
  }
}
</script>

<style>
.layout {
  display: flex;
}

.layout-content {
  flex-grow: 4;
  flex-shrink: 0;
  flex-basis: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.transparent-background {
  background-color: rgba(30, 32, 31, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.topbar-margin {
  margin-top: 67px;
}
</style>
