<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen flex justify-center items-center z-50"
    :class="{ 'transparent-background': transparentBg }"
    @click="cancel()"
  >
    <div
      class="whitespace-pre-line bg-white rounded-big shadow pt-8 pb-8 sm:px-12 text-center relative w-3/4 dialog-w m-auto"
      @click.stop
    >
      <slot name="custom-header" />
      <div
        v-if="icon || image || lottie"
        class="flex justify-center text-center pb-2"
      >
        <div v-if="icon" class="text-blue" :class="icon.color">
          <icon :name="icon.name" :small="icon.small" />
        </div>
        <div v-else-if="image" class="w-40 h-40">
          <img :src="image" />
        </div>
        <div v-else-if="lottie" class="w-40 h-40">
          <lottie-animation :path="lottie + '.json'" />
        </div>
      </div>
      <div
        v-if="title"
        class="text-blue leading-2 font-title uppercase text-xl sm:text-2xl whitespace-normal"
      >
        {{ title }}
      </div>
      <icon
        v-if="!hiddenClose"
        name="close"
        class="text-gray-400 absolute top-0 right-0 m-4 cursor-pointer"
        @click.native="cancel()"
      />
      <div v-if="htmlContent" class="text-blue text-center mt-5">
        <div v-html="htmlContent" />
      </div>
      <div v-else-if="content" class="text-blue text-center mt-5 px-2">
        {{ content }}
      </div>
      <section>
        <slot name="extra-content" />
      </section>
      <div v-if="subContent" class="text-gray-400 text-center mt-5">
        {{ subContent }}
      </div>
      <div v-if="!noLabels" class="flex pt-4 w-3/4 sm:w-full m-auto mt-5">
        <div
          v-if="secondaryLabel"
          class="flex items-center justify-center whitespace-normal p-3 px-4 text-accent border border-accent rounded-small font-bold flex-1 text-center mr-4 cursor-pointer"
          @click="secondary()"
        >
          {{ secondaryLabel }}
        </div>
        <div
          class="flex items-center justify-center whitespace-normal p-3 px-4 text-white border rounded-small font-bold flex-1 text-center cursor-pointer"
          @click="confirm()"
          :class="{
            'bg-accent border-accent': theme === 'default',
            'bg-light-blue border-light-blue-b': theme === 'light-blue'
          }"
        >
          {{ mainLabel }}
        </div>
      </div>
      <div
        v-if="tertiaryLabel"
        class="pt-4 px-4 text-accent flex justify-center underline"
      >
        <div class="cursor-pointer" @click="tertiary()">
          {{ tertiaryLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'LDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: null
    },
    htmlContent: {
      type: String,
      default: null
    },
    subContent: {
      type: String,
      default: null
    },
    mainLabel: {
      type: String,
      default: function () {
        return this.$t('ctas.confirm')
      }
    },
    secondaryLabel: {
      type: String,
      default: function () {
        return this.$t('ctas.cancel')
      }
    },
    tertiaryLabel: {
      type: String,
      default: null
    },
    noLabels: {
      type: Boolean,
      default: false
    },
    transparentBg: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Object,
      default: null
    },
    lottie: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    hiddenClose: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'default'
    }
  },
  methods: {
    deactivate() {
      this.$destroy()
      this.$el.remove()
    },
    cancel() {
      this.$emit('cancel')
      this.deactivate()
    },
    secondary() {
      this.$emit('secondary')
      this.deactivate()
    },
    confirm() {
      this.$emit('confirm')
      this.deactivate()
    },
    tertiary() {
      this.$emit('tertiary')
      this.deactivate()
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  },
  components: {
    Icon,
    LottieAnimation
  }
}
</script>

<style scoped>
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}

.text-color {
  color: #2c3a4c;
}

@media screen and (min-width: 640px) {
  .dialog-w {
    width: 30.25rem;
  }
}
</style>
