import uuid4 from 'uuid/v4'
import Vue from 'vue'
import i18n from '@/i18n.js'
import NotificationFixedList from '../components/NotificationFixedList.vue'
import NotificationFloatingList from '../components/NotificationFloatingList.vue'
import moment from 'moment'

class NotificationList {
  constructor() {
    if (process.env.NODE_ENV === 'test') return //Only needed during the migration
    this.VM = new Vue({
      data: () => ({
        fixedComponent: null,
        floatingComponent: null,
        fixedNotifications: [],
        floatingNotifications: []
      }),
      methods: {
        create(notificationData) {
          let notification = new Notification(notificationData)
          if (!this.floatingComponent) {
            this.floatingComponent = this.openFloatingComponent({
              notifications: this.floatingNotifications
            })
            this.floatingComponent.$on('action', notification.onAction)
            this.floatingComponent.$on(
              'deleteNotification',
              notificationIndex => {
                this.floatingNotifications.splice(notificationIndex, 1)
              }
            )
            this.floatingComponent.$on('progressCanceled', index => {
              this.floatingNotifications[index] = {
                ...this.floatingNotifications[index],
                progressCanceled: true
              }
            })
          }
          if (
            !this.fixedComponent &&
            !this.notificationAlreadyExists(
              notification.title,
              this.floatingNotifications
            )
          ) {
            this.floatingNotifications.unshift(notification)
          }
          if (
            notification.keepInSidePanel &&
            !this.notificationAlreadyExists(
              notification.title,
              this.fixedNotifications
            )
          ) {
            this.fixedNotifications.unshift(notification)
          }
          return notification
        },
        updateProgress(id, progress) {
          let notificationIndex = this.floatingNotifications.findIndex(
            notification => notification.id === id
          )
          if (this.floatingNotifications[notificationIndex]) {
            this.floatingNotifications[notificationIndex].setProgress(progress)
          }
        },
        openFloatingComponent(propsData) {
          const vm =
            typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
          const NotificationListComponent = vm.extend(NotificationFloatingList)
          let container = document.createElement('div')
          document.body.appendChild(container)
          return new NotificationListComponent({
            el: container,
            i18n,
            propsData
          })
        },
        openFixedComponent() {
          if (!this.fixedComponent) {
            if (this.floatingComponent) {
              this.floatingComponent.notifications = []
              this.floatingComponent = null
              this.floatingNotifications = []
            }
            const vm =
              typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
            const NotificationSideMenuComponent = vm.extend(
              NotificationFixedList
            )
            let container = document.createElement('div')
            document.body.appendChild(container)
            let propsData = { notifications: this.fixedNotifications }
            this.fixedComponent = new NotificationSideMenuComponent({
              el: container,
              i18n,
              propsData
            })
            this.fixedComponent.$on('close', () => (this.fixedComponent = null))
            this.fixedComponent.$on('deleteNotification', notificationIndex => {
              this.fixedNotifications.splice(notificationIndex, 1)
            })
            this.fixedComponent.$on('clear', () => {
              this.fixedNotifications = this.fixedNotifications.filter(
                notification =>
                  notification.keepInScreen && notification.blockDelete
              )
              this.fixedComponent.notifications = [...this.fixedNotifications]
            })
            this.fixedComponent.$on('progressCanceled', index => {
              this.fixedNotifications[index] = {
                ...this.fixedNotifications[index],
                progressCanceled: true
              }
            })
          }
        },
        notificationAlreadyExists(title, notifications) {
          return notifications.some(
            notification => notification.title === title
          )
        }
      }
    })
  }
  get state() {
    return this.VM.$data
  }
  get create() {
    return this.VM.create
  }
  get updateProgress() {
    return this.VM.updateProgress
  }
  get fixedNotifications() {
    return this.VM.$data.fixedNotifications
  }
  get openFixedComponent() {
    return this.VM.openFixedComponent
  }
}

class Notification {
  id = uuid4()
  icon = null
  iconColor = null
  title = ''
  subtitle = null
  subtitleIcon = null
  timeout = null
  keepInScreen = false
  onAction = null
  progress = null
  progressCanceled = false
  progressCancelTitle = null
  type = 'standard'
  createdAt = moment()

  constructor(notification) {
    this.icon = notification.icon
    this.iconColor = notification.iconColor
    this.title = notification.title
    this.subtitle = notification.subtitle
    this.subtitleIcon = notification.subtitleIcon
    this.timeout = notification.timeout
    this.keepInScreen = !!notification.keepInScreen
    this.onAction = notification.onAction
    this.progress = notification.progress
    this.progressCanceled = !!notification.progressCanceled
    this.progressCancelTitle = notification.progressCancelTitle
    this.type = notification.type
    this.blockDelete = notification.blockDelete
    this.keepInSidePanel =
      notification.keepInSidePanel ?? notification.keepInScreen
  }

  setProgress(progress) {
    this.progress = progress
  }
}

export let notification = new NotificationList()

const notificationPlugin = {
  install(Vue) {
    Vue.prototype['$lnotification'] = notification
  }
}

export default notificationPlugin
