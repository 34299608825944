import axios from 'axios'
import store from '@/store/index'
import { errors } from '@last/core/src/errorManager'

let api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use(
  config => {
    let token = store.state.auth.accessToken
    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },

  async error => {
    switch (error.response.data.code) {
      case errors.locationNotAllowed.code:
        await store.dispatch('locations/removeSelectedLocation')
        break
    }

    return Promise.reject(error)
  }
)

export default api
