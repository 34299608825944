<template>
  <div class="z-20 absolute top-0 right-0 h-full overflow-hidden">
    <transition name="move">
      <div
        v-if="open"
        class="bg-blue-700 panel-w h-full flex flex-col text-white relative overflow-y-hidden"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidePanel',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.panel-w {
  width: 43.75rem;
}

.move-enter-active,
.move-leave-active {
  transition: transform 0.5s;
}
.move-enter,
.move-leave-to {
  transform: translateX(100%);
}
</style>
