<template functional>
  <div class="cta flex mt-4 justify-center sm:justify-end">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cta {
  width: 100%;
  display: flex;
  position: absolute;
  right: 0;
  bottom: -66px;
}
</style>
