<template>
  <import-external-catalog
    source="Klikin"
    @import-requested="importCatalog"
    :importing="importing"
  />
</template>

<script>
import ImportExternalCatalog from './ImportExternalCatalog.vue'
import { mapState } from 'vuex'
import api from '@/api.js'

export default {
  name: 'ImportKlikinCatalog',
  components: {
    ImportExternalCatalog
  },
  emits: ['import-requested'],
  data() {
    return {
      importing: false
    }
  },
  methods: {
    async importCatalog({ url, name }) {
      this.importing = true
      try {
        await api.post(`support/klikin-catalog-import`, {
          url: url,
          catalogName: name,
          organizationId: this.selectedOrganization.id
        })
        window.open(
          `${process.env.VUE_APP_LAST_ADMIN_URL}/support/login?supportToken=${this.accessToken}&organizationId=${this.selectedOrganization.id}&redirect=catalogs`
        )
      } catch (err) {
        this.$lnotification.create({
          title: `${this.$t('import-external-catalog.import-failed')}: ${
            err.response.request.response
          }`,
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.importing = false
    }
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    ...mapState('organizations', ['selectedOrganization'])
  }
}
</script>

<style scoped></style>
