<template>
  <div class="text-center max-w-2xl mx-auto my-auto" v-click-outside="cancel">
    <div class="font-title font-bold uppercase text-2xl text-blue">
      {{
        $t('deleted-locations.recover-confirmation', {
          name: location.name
        })
      }}
    </div>
    <div class="text-gray-500 mt-4">
      {{ $t('deleted-locations.name') + ': ' }}
      <span class="text-blue">{{ location.name }}</span>
    </div>
    <div class="text-gray-500 mt-1">
      {{ $t('deleted-locations.organization') + ': ' }}
      <span class="text-blue">{{ location.organizationName }}</span>
    </div>
    <div class="text-gray-500 mt-1">
      {{ $t('deleted-locations.id') + ': ' }}
      <span class="text-blue">{{ location.id }}</span>
    </div>
    <div class="text-gray-500 mt-1">
      {{ $t('deleted-locations.address') + ': ' }}
      <span class="text-blue">{{ location.address }}</span>
    </div>
    <div class="text-gray-500 mt-1">
      {{ $t('deleted-locations.deletion-date') + ': ' }}
      <span class="text-blue">{{ deletionTime }}</span>
    </div>
    <p v-if="temporally" class="text-blue mt-4">
      {{ $t('deleted-locations.recover-temporally-info') }}
    </p>
    <div
      class="uppercase cursor-pointer select-none py-2 mt-6 mb-2 shadow mx-auto rounded-lg w-1/2 bg-red text-white border border-red-b"
      @click="recoverLocation"
      :loading="loading"
    >
      {{
        temporally
          ? $t('deleted-locations.recover-temporally')
          : $t('deleted-locations.recover')
      }}
    </div>
  </div>
</template>

<script>
import api from '@/api'
import moment from 'moment'

export default {
  name: 'RecoverLocationPopUp',
  data() {
    return {
      loading: false
    }
  },
  props: {
    location: {
      type: Object,
      required: true
    },
    temporally: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async recoverLocation() {
      if (this.loading) return
      this.loading = true
      if (this.temporally) {
        await api.put(
          `/support/location/${this.locationId}/temporal-reactivation`
        )
      } else {
        await api.put(`/support/location/${this.locationId}/recover`)
      }
      this.loading = false
      this.exit()
    },
    exit() {
      this.$emit('recovered', this.location)
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  computed: {
    deletionTime() {
      if (!this.location.deletionTime) return '-'
      return moment(this.location.deletionTime).format('DD/MM/YYYY · HH:mm')
    },
    locationId() {
      return this.location.id
    }
  }
}
</script>
