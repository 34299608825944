<template>
  <div class="flex flex-col pb-4">
    <div
      class="font-body flex place-content-between items-center"
      :class="[labelColorClass, labelFontSizeClass]"
    >
      <div>
        {{ label }} <span v-if="mandatory" class="text-red">*</span>
        <span v-if="description" class="text-blue">{{ description }}</span>
      </div>
      <slot name="top-left"></slot>
    </div>
    <div class="flex">
      <slot />
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  name: 'LField',
  props: {
    label: {
      type: String,
      default: null
    },
    labelColorClass: {
      type: String,
      default: 'text-gray-400'
    },
    labelFontSizeClass: {
      type: String,
      default: 'text-base'
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: null
    }
  }
}
</script>
