import { render, staticRenderFns } from "./DeleteTabsByDate.vue?vue&type=template&id=31898b14&scoped=true&"
import script from "./DeleteTabsByDate.vue?vue&type=script&lang=js&"
export * from "./DeleteTabsByDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31898b14",
  null
  
)

export default component.exports