var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-shrink-0 items-center",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', !_vm.value)}}},[_c('div',{staticClass:"rounded cursor-pointer flex-shrink-0 p-1",class:{
      'bg-accent bg-red border-none': _vm.value || _vm.half,
      'bg-white border border-gray-400': _vm.theme === 'light',
      'bg-transparent-gray': _vm.theme === 'dark',
      'w-4 h-4': _vm.small,
      'w-6 h-6': !_vm.small,
      'opacity-50 pointer-events-none': _vm.disabled
    }},[_c('div',{staticClass:"w-full h-full flex items-center justify-center"},[(_vm.half)?_c('div',{staticClass:"text-white font-bold text-2xl"},[_vm._v("-")]):(_vm.value)?_c('icon',{staticClass:"text-white",attrs:{"name":"check","small":""}}):_vm._e()],1)]),(_vm.label)?_c('div',{staticClass:"ml-2 cursor-pointer"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }