<template>
  <div class="flex-row-y-center">
    <div class="mr-5 text-gray-400">{{ label }}:</div>
    <div class="flex-row-y-center">
      <div
        v-if="editing"
        class="w-full text-blue flex items-center"
        @keyup.enter="confirm"
        @keyup.esc="cancel"
      >
        <l-input
          ref="input"
          v-model="internalValue"
          :placeholder="placeholder"
          class="appearance-none focus:outline-none h-full leading-tight w-auto font-mono box-content"
          :vertical-margin="false"
          :disabled="!editing"
          :icon="icon"
          icon-class="text-blue"
        />
        <div class="flex-row-y-center ml-3">
          <icon
            name="check"
            class="icon text-violet"
            @click="confirm"
            original
          />
          <icon name="close" class="icon text-blue ml-2" @click="cancel" />
        </div>
      </div>
      <div v-else class="text-blue space-x-8 flex py-3" @dblclick="edit">
        <div class="flex items-center">
          <div v-if="internalValue">
            {{ formattedIntervalValue }}
          </div>
          <div v-else class="text-gray-400">
            {{ placeholder }}
          </div>
          <icon v-if="icon" :name="icon" class="ml-1" />
        </div>
        <div class="flex-row-y-center">
          <icon name="pen" class="cursor-pointer text-violet" @click="edit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import LInput from '@last/core-ui/components/LInput'

export default {
  name: 'LEditableValue',
  inheritAttrs: false,
  components: {
    Icon,
    LInput
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Undefined'
    }
  },
  data() {
    return {
      editing: false,
      internalValue: ''
    }
  },
  created() {
    this.internalValue = this.value
  },
  methods: {
    edit() {
      this.editing = true
    },
    cancel() {
      if (this.editing) {
        this.internalValue = this.value
        this.editing = false
      }
    },
    confirm() {
      this.$emit('input', this.internalValue)
      this.editing = false
    }
  },
  computed: {
    formattedIntervalValue() {
      return this.internalValue?.length > 50
        ? `${this.internalValue.substr(0, 50)}...`
        : this.internalValue
    }
  },
  watch: {
    value() {
      this.internalValue = this.value
    },
    editing() {
      if (this.editing) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      } else {
        document.getSelection().removeAllRanges()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row-y-center {
  @apply flex flex-row items-center;
}

input::placeholder {
  @apply text-gray-350 opacity-75;
}

input {
  @apply pb-1 text-blue;
}

input:disabled {
  @apply text-violet;
}
</style>
