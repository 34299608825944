<template>
  <div class="flex items-center text-gray-400 cursor-pointer">
    <div
      class="w-4 h-4 rounded-full border circle-padding flex-shrink-0 bg-white"
      :class="[big ? 'w-6 h-6' : '']"
      @click.stop="$emit('input', !value)"
    >
      <div class="rounded-full bg-accent h-full w-full" v-if="value"></div>
    </div>
    <div
      v-if="label"
      @click="$emit('input', !value)"
      class="text-color-option pl-2"
      :class="[labelClass]"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LRadio',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: 'text-md'
    },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.circle-padding {
  padding: 3px;
}
</style>
