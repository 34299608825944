<template>
  <button
    :class="classes"
    class="focus:outline-none hover:bg-blue hover:bg-primary-dark rounded-lg"
    :disabled="loading || disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'LButton',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: String,
      default: 'rounded'
    }
  },
  computed: {
    classes() {
      let sizeClasses
      if (this.small) {
        sizeClasses = ['px-10', 'py-2']
      } else {
        sizeClasses = ['px-12', 'py-3']
      }
      let styleClasses
      if (this.border) {
        styleClasses = [
          'bg-white',
          'border',
          'border-blue',
          'hover:border-0',
          'hover:text-white'
        ]
      } else {
        styleClasses = ['bg-blue', 'bg-primary', 'text-white']
      }
      let stateClasses = []
      if (this.loading || this.disabled) {
        stateClasses = ['opacity-50']
      }
      return [this.rounded, ...styleClasses, ...sizeClasses, ...stateClasses]
    }
  }
}
</script>
