<template>
  <div class="w-full">
    <l-input
      :type="type"
      :placeholder="placeholder"
      v-model="passwordRepeated"
      :wrong-value="wrongValue"
      :error-message="errorMessage"
      :text-color-class="passwordClass"
      @enter="$emit('enter')"
    >
      <template slot="icons">
        <icon
          @click.native="changeType"
          :name="iconName"
          class="mr-4"
          :class="iconClass"
        />
        <icon v-if="!wrongValue" name="lock" class="mr-4" :class="iconClass" />
        <icon v-else name="alert" class="mr-4 text-red" />
      </template>
    </l-input>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'LInputPassword',
  components: {
    Icon,
    LInput
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'text-red'
    },
    wrongValue: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  data: () => ({
    type: 'password'
  }),
  computed: {
    iconName() {
      return this.type === 'password' ? 'eye' : 'eye-disabled'
    },
    passwordRepeated: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    passwordClass() {
      return this.type === 'password' ? this.iconClass : 'text-blue'
    }
  },
  methods: {
    changeType() {
      this.type = this.type === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped></style>
