var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[(!_vm.dragDrop)?[_vm._t("default")]:_c('div',{staticClass:"w-full h-full flex items-center justify-center overflow-hidden border cursor-pointer text-gray-400",class:{
      'border-violet box-border': _vm.dragDropFocus,
      'border-dashed': !_vm.dragDropFocus,
      'bg-transparent-gray border-gray-400': _vm.theme === 'dark',
      'bg-transparent-light-gray border-gray-300': _vm.theme === 'light',
      'cursor-pointer': !_vm.disabled,
      'pointer-events-none': _vm.disabled,
      'rounded-full': _vm.circle,
      'rounded-small': !_vm.circle
    },on:{"dragover":function($event){$event.preventDefault();return _vm.updateDragDropFocus(true)},"dragleave":function($event){$event.preventDefault();return _vm.updateDragDropFocus(false)},"dragenter":function($event){$event.preventDefault();return _vm.updateDragDropFocus(true)},"drop":function($event){$event.preventDefault();return _vm.onFileChange.apply(null, arguments)}}},[_vm._t("default",function(){return [_c('div',{staticClass:"flex flex-col items-center p-2"},[_c('icon',{staticClass:"text-violet",attrs:{"name":"upload"}}),(_vm.text)?_c('span',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1)]})],2),_c('input',_vm._b({ref:"uploadInput",staticClass:"hidden",attrs:{"type":"file","multiple":_vm.multiple,"accept":_vm.accept,"disabled":_vm.disabled},on:{"change":_vm.onFileChange}},'input',_vm.$attrs,false))],2)}
var staticRenderFns = []

export { render, staticRenderFns }