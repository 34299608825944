<template>
  <div id="app">
    <router-view></router-view>
    <portal-target name="appRoot"> </portal-target>
  </div>
</template>

<script>
export default {
  name: 'App'
  //
}
</script>

<style></style>
