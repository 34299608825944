<template>
  <div>
    <l-card :title="$t('organization-demo.title')">
      <div class="flex justify-between items-center text-blue">
        {{ $t('organization-demo.text') }}
        <l-switch :value="demoOrganization" @input="setDemoOrganization" />
      </div>
    </l-card>
  </div>
</template>
<script>
import LCard from '@last/core-ui/components/LCard'
import LSwitch from '@last/core-ui/components/LSwitch'
import api from '@/api.js'
import { mapState } from 'vuex'
export default {
  name: 'DemoOrganization',
  components: {
    LCard,
    LSwitch
  },
  data() {
    return {
      demoOrganization: false
    }
  },
  mounted() {
    this.demoOrganization = this.selectedOrganization?.isDemo
  },
  methods: {
    async setDemoOrganization(value) {
      await api.put(
        `/support/organization/${this.selectedOrganization.id}/demo`,
        {
          isDemo: value
        }
      )
      this.demoOrganization = value
    }
  },
  computed: {
    ...mapState('organizations', ['selectedOrganization'])
  },
  watch: {
    selectedOrganization() {
      this.demoOrganization = this.selectedOrganization.isDemo
    }
  }
}
</script>
