<template>
  <div class="w-full">
    <div
      :class="[
        textColorClass,
        {
          'border-red': wrongValue,
          'hover:border-red': wrongValue,
          'focus-within:border-red': wrongValue,
          'my-2': verticalMargin,
          'bg-white border': theme === 'light',
          'bg-gray-100': theme === 'semi',
          'bg-transparent-gray': theme === 'dark',
          'opacity-50': disabled,
          'hover:border-gray-400': !disabled,
          'h-full': inputType === 'textarea'
        }
      ]"
      class="w-full border-gray-300 rounded-small focus-within:border-blue flex items-center"
    >
      <slot>
        <input
          v-if="inputType === 'input'"
          ref="input"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-8': giant,
            'py-3': !small && !big && !giant && !maskIt,
            'bg-transparent text-white': theme === 'dark',
            'py-3 tracking-widest': maskIt
          }"
          v-model="computedValue"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
          :type="type"
          :maxlength="maxLength"
          @keyup.enter="$emit('enter')"
        />
        <textarea
          ref="input"
          v-else-if="inputType === 'textarea'"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-8': giant,
            'py-3': !small && !big && !giant,
            'bg-transparent text-white': theme === 'dark',
            'bg-gray-100': theme === 'semi',
            'text-blue': theme === 'light'
          }"
          v-model="computedValue"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
          :type="type"
          :maxlength="maxLength"
        />
        <slot name="icons">
          <div class="h-full rounded-small flex items-center">
            <icon v-if="icon" :name="icon" class="mr-4" :class="iconClass" />
            <icon v-if="wrongValue" name="alert" class="mr-4 text-red" />
          </div>
        </slot>
        <div v-if="!icon && endPlaceholder" class="h-full flex items-center">
          <div
            class="mr-4"
            :class="'text-' + (endPlaceholderColor || 'gray-400')"
          >
            {{ endPlaceholder }}
          </div>
        </div>
      </slot>
    </div>
    <div v-if="wrongValue && errorMessage" class="text-xs text-red ml-4">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LInput',
  props: {
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    endPlaceholder: {
      type: String,
      default: null
    },
    endPlaceholderColor: {
      type: String,
      default: 'gray-400'
    },
    iconClass: {
      type: String,
      default: 'text-red'
    },
    value: {
      type: [String, Number],
      default: null
    },
    small: Boolean,
    wrongValue: {
      type: Boolean,
      default: false
    },
    verticalMargin: {
      type: Boolean,
      default: true
    },
    big: Boolean,
    giant: Boolean,
    isCurrency: {
      type: Boolean,
      default: false
    },
    currencyDecimals: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    inputType: {
      type: String,
      default: 'input'
    },
    type: {
      type: String,
      default: 'text'
    },
    errorMessage: {
      type: String,
      default: null
    },
    textColorClass: {
      type: String,
      default: 'text-blue'
    },
    maxLength: {
      type: Number,
      default: 255
    }
  },
  mounted() {
    if (this.type === 'number') {
      this.$refs.input.addEventListener('mousewheel', event =>
        event.preventDefault()
      )
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.isCurrency && this.value != 'Mixed'
          ? this.value / Math.pow(10, this.currencyDecimals)
          : this.value
      },
      set(value) {
        if (this.isCurrency && value != 'Mixed') {
          let roundedValue = Math.round(
            String(value).replace(',', '.') *
              Math.pow(10, this.currencyDecimals)
          )
          this.$emit('input', isNaN(roundedValue) ? null : roundedValue)
        } else {
          this.$emit('input', value)
        }
      }
    },
    maskIt() {
      return this.type === 'password' && this.computedValue
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  },
  components: {
    Icon
  }
}
</script>
