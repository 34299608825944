import './style.scss'
import Vue from 'vue'
import './devCode.js'
import App from './App.vue'
import router from './router.js'
import store from '@/store/index'
import './filters'
import vClickOutside from 'v-click-outside'
import i18n from './i18n'
import PortalVue from 'portal-vue'
import LNotificationPlugin from '@last/core-ui/plugins/notification'
import LDialogPlugin from '@last/core-ui/plugins/dialog.js'

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(vClickOutside)
Vue.use(LNotificationPlugin)
Vue.use(LDialogPlugin)

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
