<template>
  <div
    class="switch-bg bg-gray-300 rounded-full flex items-center"
    @click.stop="emitInput"
    :class="{
      'bg-red': !value,
      'bg-green': value == 1,
      'bg-gray-400': value === 2,
      'opacity-50': disabled,
      'cursor-pointer': !disabled
    }"
  >
    <div
      class="rounded-full bg-white shadow switch-button"
      :class="{ 'active-button': value == 1, 'half-button': value == 2 }"
    />
  </div>
</template>

<script>
export default {
  name: 'LSwitch',
  props: {
    value: {
      type: [Boolean, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitInput() {
      if (!this.disabled) this.$emit('input', !this.value)
    }
  }
}
</script>

<style>
.switch-bg {
  width: 36px;
  height: 16px;
  transition: all 0.5s;
}

.switch-button {
  transition: all 0.5s;
  width: 14px;
  height: 14px;
  margin: 1px;
}

.active-button {
  margin-left: 22px;
}

.half-button {
  margin-left: 11px;
}
</style>
