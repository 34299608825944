<template>
  <div>
    <l-card :title="$t('global-settings.organization-admin-title')">
      <div class="flex flex-row justify-between items-center">
        <div class="text-gray-400">
          {{ $t('global-settings.organization-admin-message') }}
        </div>
        <div
          class="border border-violet text-violet rounded-lg px-5 py-2 w-1/4 text-center cursor-pointer"
          @click="goToAdmin"
        >
          {{ $t('global-settings.organization-admin-title') }}
        </div>
      </div>
    </l-card>
    <l-card :title="$t('global-settings.organization-pos-title')">
      <div class="flex flex-row justify-between items-center">
        <div class="text-gray-400">
          {{ $t('global-settings.organization-pos-message') }}
        </div>
        <div class="w-1/4">
          <div
            class="border border-violet text-violet rounded-lg text-center py-2 cursor-pointer"
            @click="goToPOS"
          >
            {{ $t('global-settings.organization-pos-cta') }}
            <span v-if="posBeta">Beta</span>
          </div>
          <div
            class="text-sm text-violet text-center cursor-pointer pt-2"
            @click="posBeta = !posBeta"
          >
            {{ $t('global-settings.change') }}
          </div>
        </div>
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import { mapState } from 'vuex'

export default {
  name: 'SupportAccess',
  data() {
    return {
      posBeta: true
    }
  },
  methods: {
    goToAdmin() {
      window.open(
        `${process.env.VUE_APP_LAST_ADMIN_URL}/support/login?supportToken=${
          this.accessToken
        }&organizationId=${this.selectedOrganization.id}${
          this.selectedLocation?.id !== -1
            ? `&locationId=${this.selectedLocation?.id}`
            : ''
        }`
      )
    },
    goToPOS() {
      let url = process.env.VUE_APP_LAST_POS_URL
      if (this.posBeta && url.includes('pos')) {
        url = url.replace('pos', 'pos-beta')
      }
      window.open(
        `${url}/#/support-login?supportToken=${
          this.accessToken
        }&organizationId=${this.selectedOrganization.id}&refreshLocalStorage${
          this.selectedLocation?.id !== -1
            ? `&locationId=${this.selectedLocation?.id}`
            : ''
        }`
      )
    }
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    ...mapState('organizations', ['selectedOrganization', 'selectedLocation'])
  },
  components: {
    LCard
  }
}
</script>

<style scoped></style>
