<template>
  <div class="spinner">
    <div
      :class="[`spinner-icon--${size}`, `spinner-icon--${theme}`]"
      class="spinner-icon"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    theme: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  animation: loading-bar-spinner 0.6s linear infinite;
  & .spinner-icon {
    border-radius: 50%;
    &--light {
      border-top-color: #fff !important;
      border-left-color: #fff !important;
    }
    &--dark {
      border-top-color: #00c8b1 !important;
      border-left-color: #00c8b1 !important;
    }
    &--small {
      width: 20px;
      height: 20px;
      border: solid 2px transparent;
    }
    &--medium {
      width: 40px;
      height: 40px;
      border: solid 4px transparent;
    }
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
