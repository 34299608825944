<template>
  <div
    v-if="svg"
    class="flex items-center justify-center flex-shrink-0"
    @click="$emit('click')"
    :class="{
      'opacity-50 pointer-events-none': disabled,
      'current-color': !originalColor,
      'w-6 h-6': !this.small && !this.big && !this.giant,
      'w-4 h-4': this.small,
      'w-10 h-10': this.big,
      'w-28 h-28': this.giant
    }"
    v-html="svg"
  ></div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    giant: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    originalColor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    svg() {
      try {
        return require(`!svg-inline-loader?classPrefix&idPrefix!./assets/svg/${this.name}.svg`)
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style>
.current-color path {
  fill: currentColor;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
