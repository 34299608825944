<template>
  <side-panel-view @close="$emit('close')">
    <div>
      <l-field :label="$t('public-api.name')" v-if="editingUser" mandatory>
        <l-input
          v-model="editingUser.name"
          theme="dark"
          :wrong-value="showErrors && nameError"
        />
      </l-field>
      <l-field :label="$t('public-api.source')" v-if="editingUser" mandatory>
        <l-input
          v-model="editingUser.source"
          theme="dark"
          :wrong-value="showErrors && sourceError"
        />
      </l-field>
      <l-field :label="$t('public-api.is-order-creator')" v-if="editingUser">
        <l-checkbox v-model="editingUser.isOrderCreator" class="mt-2" />
      </l-field>
      <l-field :label="$t('public-api.is-delivery')" v-if="editingUser">
        <l-checkbox v-model="editingUser.isDelivery" class="mt-2" />
      </l-field>
      <l-field :label="$t('public-api.max-bundle-size')" v-if="editingUser">
        <l-input
          v-model="editingUser.maxBundleSize"
          type="number"
          theme="dark"
        />
      </l-field>
      <l-field :label="$t('public-api.webhook')" v-if="editingUser">
        <l-input v-model="editingUser.webhookEndpoint" theme="dark" />
      </l-field>
      <l-field :label="$t('public-api.events')" v-if="editingUser">
        <l-select
          :options="webhookEventOptions"
          option-label="eventType"
          option-value="eventType"
          v-model="editingUserWebhooks"
          multiselect
          theme="dark"
        />
      </l-field>
    </div>
    <template #footer>
      <button
        @click="saveUser"
        class="mt-6 py-2 px-10 border rounded-xs uppercase text-sm bg-violet text-white border-violet transition"
      >
        {{ user ? $t('public-api.save') : $t('public-api.generate') }}
      </button>
    </template>
  </side-panel-view>
</template>

<script>
import SidePanelView from '@last/core-ui/components/SidePanelView.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LField from '@last/core-ui/components/LField.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import Vue from 'vue'

export default {
  name: 'PublicApiUserEditor',
  data() {
    return {
      editingUser: {},
      webhookEventOptions: [
        {
          eventType: 'tab:created'
        },
        {
          eventType: 'tab:closed'
        },
        {
          eventType: 'tab:cancelled'
        },
        {
          eventType: 'tab:delivery-status-updated'
        },
        {
          eventType: 'customer:created'
        },
        {
          eventType: 'customer:updated'
        },
        {
          eventType: 'customer_points:updated'
        },
        {
          eventType: 'catalog:updated'
        },
        {
          eventType: 'promotion:created'
        },
        {
          eventType: 'promotion:updated'
        },
        {
          eventType: 'promotion:deleted'
        },
        {
          eventType: 'shipment:sent'
        },
        {
          eventType: 'shipment:cancelled'
        },
        {
          eventType: 'kitchen_order:created'
        },
        {
          eventType: 'kitchen_order:updated'
        },
        {
          eventType: 'kitchen_note:created'
        },
        {
          eventType: 'floorplan:updated'
        },
        {
          eventType: 'floorplan:deleted'
        },
        {
          eventType: 'reservation:created'
        },
        {
          eventType: 'reservation:updated'
        },
        {
          eventType: 'reservation:cancelled'
        }
      ],
      showErrors: false
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    saveUser() {
      if (this.isValid) this.$emit('saveUser', this.editingUser)
      else this.showErrors = true
    }
  },
  mounted() {
    if (this.user) {
      this.editingUser = {
        ...this.user,
        isDelivery: !!this.user.delivery,
        maxBundleSize: this.user.delivery?.maxBundleSize
      }
    }
  },
  computed: {
    editingUserWebhooks: {
      get() {
        return this.editingUser.webhooks?.map(webhook => webhook.eventType)
      },
      set(values) {
        Vue.set(
          this.editingUser,
          'webhooks',
          values.map(val => {
            return {
              eventType: val,
              userId: this.editingUser?.id
            }
          })
        )
      }
    },
    isValid() {
      return !this.nameError && !this.sourceError
    },
    nameError() {
      if (!this.editingUser.name || this.editingUser.name === '') return true
      return false
    },
    sourceError() {
      if (!this.editingUser.source || this.editingUser.source === '')
        return true
      return false
    }
  },
  components: {
    SidePanelView,
    LInput,
    LField,
    LSelect,
    LCheckbox
  }
}
</script>

<style scoped></style>
