<template>
  <div class="bg-white h-full flex flex-row">
    <div class="organizations-w pl-5 pt-5 overflow-hidden h-full">
      <div class="mt-3">
        <router-link
          :to="{ name: 'global' }"
          class="flex items-center text-blue text-sm"
        >
          <icon name="arrow-left" />
          <div>{{ $t('settings-menu.back-to-search-engine') }}</div>
        </router-link>
      </div>
      <div class="py-3 text-blue text-xl font-bold uppercase">
        {{ $t('settings-menu.configuration') }}
      </div>
      <div
        v-for="menuItem in menuItems"
        :key="menuItem.routeName"
        class="menu-item border-violet hover:bg-gray-100 text-blue"
        :class="{
          'bg-gray-100 border-r-4 text-violet': isSelected(menuItem)
        }"
      >
        <router-link
          :to="{ name: menuItem.routeName }"
          class="py-3 flex items-center text-sm"
        >
          <icon :name="menuItem.icon" />
          <div class="ml-2">{{ menuItem.name }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'SettingsMenu',
  components: {
    Icon
  },
  data() {
    return {}
  },
  computed: {
    menuItems() {
      return [
        {
          name: this.$t('settings-menu.error-messages'),
          routeName: 'errors',
          icon: 'alert'
        },
        {
          name: this.$t('settings-menu.billing'),
          routeName: 'billing',
          icon: 'euro'
        },
        {
          name: this.$t('settings-menu.deleted-locations'),
          routeName: 'deletedLocations',
          icon: 'trash'
        },
        {
          name: this.$t('settings-menu.app-versions'),
          routeName: 'appVersions',
          icon: 'device'
        },
        {
          name: this.$t('settings-menu.integrators'),
          routeName: 'integrators',
          icon: 'integration'
        }
      ]
    }
  },
  methods: {
    isSelected(item) {
      return this.$route.name === item.routeName
    }
  }
}
</script>

<style scoped>
.organizations-w {
  width: 17.5rem;
}
</style>
