var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.svg)?_c('div',{staticClass:"flex items-center justify-center flex-shrink-0",class:{
    'opacity-50 pointer-events-none': _vm.disabled,
    'current-color': !_vm.originalColor,
    'w-6 h-6': !this.small && !this.big && !this.giant,
    'w-4 h-4': this.small,
    'w-10 h-10': this.big,
    'w-28 h-28': this.giant
  },domProps:{"innerHTML":_vm._s(_vm.svg)},on:{"click":function($event){return _vm.$emit('click')}}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }