<template>
  <div class="flex text-gray-300 border-b-2">
    <div
      v-for="item in tabs"
      :key="item.name"
      :class="{ 'border-b-2 border-blue text-blue': isSelected(item) }"
      @click="select(item)"
      class="py-4 px-6 cursor-pointer -margin-2"
    >
      {{ item.name }}
      <div id="selected-bar" v-if="isSelected(item)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: ['tabs', 'selectedTab'],
  methods: {
    isSelected(item) {
      return this.selectedTab.component === item.component
    },
    select(item) {
      this.$emit('update:selectedTab', item)
    }
  }
}
</script>

<style>
.-margin-2 {
  margin-bottom: -2px;
}
</style>
