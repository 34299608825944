const patterns = [
  /^[A-Z][-]\d{6}[-][A-Z]$/, //** AD
  /^(AT)?U(\d{8})$/, //** Austria
  /^(BE)?(0?\d{9})$/, //** Belgium
  /^(BG)?(\d{9,10})$/, //** Bulgaria
  /^(CHE)?(\d{9})(MWST|TVA|IVA)?$/, //** Switzerland
  /^(CY)?([0-59]\d{7}[A-Z])$/, //** Cyprus
  /^(CZ)?(\d{8,10})(\d{3})?$/, //** Czech Republic
  /^(DE)?([1-9]\d{8})$/, //** Germany
  /^\d{2,3}\/\d{3}\/\d{5}$/, //** DE OLD steuernummer
  /^\d{9}$/, //** DE OLD umsatzsteuerIdentifikationsnummer
  /^(DK)?(\d{8})$/, //** Denmark
  /^(EE)?(10\d{7})$/, //** Estonia
  /^(EL)?(\d{9})$/, //** Greece
  /^(ES)?([A-Z]\d{8})$/, //** Spain (National juridical entities)
  /^(ES)?([A-HN-SW]\d{7}[A-J])$/, //** Spain (Other juridical entities)
  /^(ES)?([0-9YZ]\d{7}[A-Z])$/, //** Spain (Personal entities type 1)
  /^(ES)?([KLMX]\d{7}[A-Z])$/, //** Spain (Personal entities type 2)
  /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/, //** OLD ES CIF
  /^(\d{8})([A-Z])$/, //** OLD ES NIF
  /^[XYZ]\d{7}[A-Z]$/, //** OLD ES NIE
  /^(EU)?(\d{9})$/, //** EU-type
  /^(FI)?(\d{8})$/, //** Finland
  /^(FR)?(\d{11})$/, //** France (1)
  /^(FR)?([A-HJ-NP-Z]\d{10})$/, // France (2)
  /^(FR)?(\d[A-HJ-NP-Z]\d{9})$/, // France (3)
  /^(FR)?([A-HJ-NP-Z]{2}\d{9})$/, // France (4)
  /^(GB)?(\d{9})$/, //** UK (Standard)
  /^(GB)?(\d{12})$/, //** UK (Branches)
  /^(GB)?(GD\d{3})$/, //** UK (Government)
  /^(GB)?(HA\d{3})$/, //** UK (Health authority)
  /^[A-CEGHJ-PR-TW-Z]{2}\d{6}[A-CEGHJ-PR-TW-Z]$/, //** OLD GB NIN
  /^\d{8}$/, //** OLD GB CRN
  /^(HR)?(\d{11})$/, //** Croatia
  /^(HU)?(\d{8})$/, //** Hungary
  /^(IE)?(\d{7}[A-W])$/, //** Ireland (1)
  /^(IE)?([7-9][A-Z\*\+)]\d{5}[A-W])$/, //** Ireland (2)
  /^(IE)?(\d{7}[A-W][AH])$/, //** Ireland (3)
  /^(IT)?(\d{11})$/, //** Italy
  /^(LV)?(\d{11})$/, //** Latvia
  /^(LT)?(\d{9}|\d{12})$/, //** Lithunia
  /^(LU)?(\d{8})$/, //** Luxembourg
  /^(MT)?([1-9]\d{7})$/, //** Malta
  /^(NL)?(\d{9})B\d{2}$/, //** Netherlands
  /^(NO)?(\d{9})$/, //** Norway (not EU)
  /^(PL)?(\d{10})$/, //** Poland
  /^(PT)?(\d{9})$/, //** Portugal
  /^(RO)?([1-9]\d{1,9})$/, //** Romania
  /^(RU)?(\d{10}|\d{12})$/, //** Russia
  /^(RS)?(\d{9})$/, //** Serbia
  /^(SI)?([1-9]\d{7})$/, //** Slovenia
  /^(SK)?([1-9]\d[2346-9]\d{7})$/, //** Slovakia Republic
  /^(SE)?(\d{10}01)$/ //** Sweden
]

function isValid(VAT) {
  return patterns.some(pattern => pattern.test(VAT))
}

export default {
  isValid
}
