import Vue from 'vue'
import i18n from '@/i18n.js'
import LDialog from '../components/LDialog.vue'

function open(propsData) {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
  const DialogComponent = vm.extend(LDialog)
  const parent = document.getElementById('app') || document.body
  let container = document.createElement('div')
  parent.appendChild(container)
  return new DialogComponent({
    el: container,
    i18n: window.i18n || i18n,
    propsData
  })
}

export function dialog({
  title,
  mainLabel,
  secondaryLabel,
  tertiaryLabel,
  content,
  htmlContent,
  subContent,
  icon,
  iconColor,
  onConfirm,
  onSecondary,
  onTertiary,
  onCancel,
  noLabels,
  hiddenClose,
  lottie,
  image
}) {
  let component = open({
    title,
    icon: icon && { name: icon, color: iconColor },
    mainLabel: mainLabel,
    secondaryLabel: secondaryLabel,
    tertiaryLabel,
    content,
    htmlContent,
    subContent,
    noLabels,
    hiddenClose,
    lottie,
    image
  })
  let empty = () => {}
  if (secondaryLabel && !onSecondary) onSecondary = onCancel || empty

  component.$slots.default = [content]
  if (onConfirm) {
    component.$on('confirm', onConfirm)
  }
  if (onTertiary) {
    component.$on('tertiary', onTertiary)
  }
  if (onSecondary) {
    component.$on('secondary', onSecondary)
  }
  component.$on('cancel', onCancel || empty)
  component.$mount()
}

const Plugin = {
  install(Vue) {
    Vue.component('LDialog', LDialog)
    Vue.prototype['$ldialog'] = dialog
  }
}

export default Plugin
