<template>
  <l-card :title="$t('organization-shops.title')" overflow-visible>
    <template slot="top-right">
      <div
        @click="addDomain"
        class="flex flex-row items-center justify-center rounded-full w-8 h-8 flex-shrink-0 cursor-pointer bg-red text-white font-hairline text-3xl"
      >
        <icon name="plus" small />
      </div>
    </template>
    <div
      class="flex py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      <div class="flex-1 flex-shrink-0">
        {{ $t('organization-shops.brand') }}
      </div>
      <div class="flex-1 flex-shrink-0">
        {{ $t('organization-shops.domain') }}
      </div>
      <div class="w-32"></div>
    </div>
    <div
      class="flex py-3 text-blue"
      v-for="domain in domains"
      :key="domain.name"
    >
      <div class="flex-1 flex-shrink-0">
        {{ domain.brandName }}
      </div>
      <div class="flex-1 flex-shrink-0">
        {{ domain.domain }}
      </div>
      <div class="w-32 flex justify-end">
        <icon
          name="trash"
          class="text-blue cursor-pointer"
          @click="deleteDomain(domain.domain)"
        />
      </div>
    </div>
    <div class="flex py-3 text-blue items-center" v-if="addingDomain">
      <div class="flex-1 flex-shrink-0 pr-6">
        <l-select
          :options="brands"
          option-value="id"
          option-label="name"
          v-model="newDomain.brandId"
        />
      </div>
      <div class="flex-1 flex-shrink-0 pr-6">
        <l-input v-model="newDomain.domain" />
      </div>
      <div class="w-32 flex justify-end items-center" v-if="!creating">
        <icon
          name="check"
          class="text-violet cursor-pointer mr-2"
          @click="createDomain"
        />
        <icon name="close" class="text-blue cursor-pointer" @click="cancel" />
      </div>
      <div class="w-32 flex justify-end items-center" v-else>
        <div class="w-6 mr-4">
          <lottie-animation path="lottie-loading.json" />
        </div>
        {{ $t('organization-shops.creating') }}
      </div>
    </div>
  </l-card>
</template>

<script>
import LCard from '@last/core-ui/components/LCard.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import api from '@/api.js'
import { mapState } from 'vuex'

export default {
  name: 'OrganizationShopDomains',
  components: {
    LCard,
    LInput,
    LSelect,
    Icon,
    LottieAnimation
  },
  data() {
    return {
      domains: [],
      brands: [],
      addingDomain: false,
      creating: false,
      newDomain: {}
    }
  },
  async mounted() {
    await this.refreshDomains()
  },
  methods: {
    async refreshDomains() {
      if (this.selectedOrganization) {
        let result = await api.get(
          `/support/organization/${this.selectedOrganization.id}/shop-domains`
        )
        this.domains = result.data.domains
        this.brands = result.data.brands
      }
    },
    async deleteDomain(domain) {
      await api.delete(
        `/support/organization/${this.selectedOrganization.id}/shop-domains/${domain}`
      )
      await this.refreshDomains()
    },
    async createDomain() {
      this.creating = true
      try {
        await api.post(
          `/support/organization/${this.selectedOrganization.id}/shop-domains`,
          this.newDomain
        )
        await this.refreshDomains()
        this.addingDomain = false
      } catch {
        this.$lnotification.create({
          title: this.$t('organization-shops.error'),
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.creating = false
    },
    cancel() {
      this.addingDomain = false
    },
    addDomain() {
      this.addingDomain = true
      this.newDomain = {
        domain: null,
        brandId: null
      }
    }
  },
  computed: {
    ...mapState('organizations', ['selectedOrganization'])
  },
  watch: {
    async selectedOrganization() {
      await this.refreshDomains()
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
