<template>
  <div class="bg flex justify-between items-center px-4 z-30">
    <div class="flex items-center">
      <icon
        v-if="menuAvailable"
        name="expand"
        @click.native="$emit('toggleMenu')"
        :class="{ rotated: menuExpanded }"
        class="transition text-violet flex-shrink-0 mr-2 sm:mr-8 xl:hidden hidden sm:block"
      />
      <div class="w-1/2">
        <img
          v-if="!devMode"
          src="../assets/logo-new.png"
          class="sm:py-3 logo"
        />
        <img v-else src="../assets/logo-hack.png" class="sm:py-3 logo-hack" />
      </div>
    </div>
    <div v-if="isAuthenticated" class="items-center hidden sm:flex">
      <div
        v-if="settingsAvailable"
        class="-mr-2 ml-4 cursor-pointer"
        @click="goToSettings"
      >
        <icon name="settings" class="text-violet" />
      </div>
      <div class="mx-4 text-sm text-white hidden sm:flex items-center mr-8">
        <icon name="user" class="mx-2 text-gray-400" />
        <div class="text-blue">{{ user.name }}</div>
      </div>
      <l-button @click="logoutAndLeave" small border class="text-blue">{{
        $t('menu.logout')
      }}</l-button>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LButton from '@last/core-ui/components/LButton'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'TopBar',
  props: {
    menuExpanded: {
      type: Boolean,
      default: false
    },
    menuAvailable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('organizations', ['removeSelectedOrganization']),
    async logoutAndLeave() {
      await this.logout()
      let route = this.$router.resolve({ name: 'login' })
      location.href = route.href
    },
    goToSettings() {
      this.removeSelectedOrganization()
      this.$router.push({ name: 'errors' })
    }
  },
  computed: {
    ...mapState('auth', ['user', 'isAuthenticated', 'devMode']),
    ...mapGetters('auth', ['isAuthenticated']),
    logo() {
      return this.devMode ? '../assets/logo-hack.png' : '../assets/logo-new.png'
    },
    settingsAvailable() {
      return (
        !this.$route.path.includes('/settings/') &&
        !this.$route.name.includes('settings')
      )
    }
  },
  components: {
    Icon,
    LButton
  }
}
</script>

<style scoped>
.bg {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.transition {
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(180);
}

.logo {
  max-width: 175px;
}

.logo-hack {
  max-width: 200px;
}
</style>
