import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import organizations from './organizations'
import integrations from './integrations'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    organizations,
    integrations
  }
})
