<template>
  <div class="h-full overflow-hidden relative">
    <transition :name="transitionName">
      <div
        class="h-full overflow-hidden panel-w absolute top-0"
        v-if="!showNext"
      >
        <div
          @click="$emit('close')"
          class="absolute top-0 right-0 p-4 cursor-pointer"
        >
          <icon name="close" class="text-gray-400" />
        </div>
        <div class="overflow-y-scroll px-12 h-full pt-12 pb-20">
          <slot />
        </div>
        <div class="absolute bottom-0 my-6 px-12">
          <slot name="footer" />
        </div>
      </div>
      <slot name="next" class="absolute top-0" v-else />
    </transition>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'SidePanelView',
  props: {
    showNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    transitionName() {
      return this.showNext ? 'movenext' : 'move'
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.panel-w {
  width: 43.75rem;
}
.move-enter-active,
.move-leave-active {
  transition: transform 0.5s;
}
.move-leave-to {
  transform: translateX(100%);
}

.move-enter {
  transform: translateX(-100%);
}

.movenext-enter-active,
.movenext-leave-active {
  transition: transform 0.5s;
}
.movenext-leave-to {
  transform: translateX(-100%);
}

.movenext-enter {
  transform: translateX(100%);
}
</style>
