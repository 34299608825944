import Vue from 'vue'
import moment from 'moment'
import lastUtils from '@last/core/src/lastUtils'

Vue.filter('currency', function (value) {
  let currencyCode = 'EUR'
  return lastUtils.currencyFilter(value, currencyCode)
})

Vue.filter('date', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm')
  }
})

Vue.filter('ellapsedTime', function (value) {
  if (value) {
    var now = moment(new Date())
    var start = moment(value)
    var duration = moment.duration(now.diff(start))
    return (
      Math.floor(duration.asHours()) +
      moment.utc(duration.asMilliseconds()).format(':mm')
    )
  }
})

Vue.filter('time', function (value) {
  if (value) {
    return moment(value).format('HH:mm')
  }
})

Vue.filter('day', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
})
