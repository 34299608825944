<template>
  <div class="flex flex-start py-2">
    <div class="mr-5 py-4 w-1/6">{{ label }}:</div>
    <div class="flex-row-y-center">
      <div v-if="editing" class="w-full bg-white text-blue flex flex-col">
        <div
          v-for="(range, index) in internalRanges"
          :key="index"
          class="flex space-x-8 py-2 items-center"
          @keyup.enter="confirm"
          @keyup.esc="cancel"
        >
          <l-input
            v-model="range.units"
            class="flex-1 appearance-none focus:outline-none h-full leading-tight w-auto font-mono box-content bg-white"
            :vertical-margin="false"
            :end-placeholder="placeholder"
          />
          <l-input
            v-model="range.price"
            class="flex-1 appearance-none focus:outline-none h-full leading-tight w-auto font-mono box-content bg-white"
            :vertical-margin="false"
            :icon="currency"
            :currency-decimals="currencyDecimals"
            is-currency
            icon-class="text-blue"
          />
          <div v-if="index == 0" class="flex ml-3">
            <icon name="check" class="icon text-red" @click="confirm" />
            <icon name="close" class="icon text-blue ml-2" @click="cancel" />
          </div>
          <div v-else class="flex ml-3">
            <icon
              name="trash"
              class="cursor-pointer text-blue"
              @click="removeRange(index)"
            />
            <div class="w-6 ml-2"></div>
          </div>
        </div>
        <div
          v-if="!rangesLimit || ranges.length < rangesLimit"
          class="flex items-center py-3 cursor-pointer"
          @click="addRange()"
        >
          <icon name="plus" class="text-violet" small />
          <div class="text-violet ml-3">Add new {{ label.toLowerCase() }}</div>
        </div>
      </div>
      <div v-else class="flex flex-col" @dblclick="edit">
        <div
          v-for="(range, index) in internalRanges"
          :key="index"
          class="flex space-x-8 text-blue py-4 justify-between"
        >
          <div class="flex flex-1">
            <div>
              {{ range.units }}
            </div>
            <div class="text-gray-400 ml-1">
              {{ placeholder }}
            </div>
          </div>
          <div class="flex flex-initial">
            <div>
              {{ formatPrice(range.price) }}
            </div>
            <icon :name="currency" class="ml-1" />
          </div>
          <icon
            v-if="index === 0"
            name="pen"
            class="cursor-pointer text-violet"
            @click="edit"
          />
          <div v-else class="w-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import LInput from '@last/core-ui/components/LInput.vue'

export default {
  name: 'LEditableRanges',
  components: {
    Icon,
    LInput
  },
  inheritAttrs: false,
  props: {
    ranges: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: 'euro'
    },
    placeholder: {
      type: String,
      default: ''
    },
    currencyDecimals: {
      type: Number,
      default: 2
    },
    rangesLimit: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      editing: false,
      internalRanges: []
    }
  },
  watch: {
    ranges(value) {
      this.internalRanges = JSON.parse(JSON.stringify(value))
    }
  },
  mounted() {
    this.internalRanges = JSON.parse(JSON.stringify(this.ranges))
  },
  methods: {
    edit() {
      this.editing = true
    },
    cancel() {
      if (this.editing) {
        this.internalRanges = JSON.parse(JSON.stringify(this.ranges))
        this.editing = false
      }
    },
    confirm() {
      this.$emit('input', this.internalRanges)
      this.editing = false
    },
    addRange() {
      this.internalRanges.push({ units: 0, price: 0 })
    },
    removeRange(index) {
      this.internalRanges.splice(index, 1)
    },
    formatPrice(price) {
      return price / Math.pow(10, this.currencyDecimals)
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row-y-center {
  @apply flex flex-row items-center;
}
</style>
